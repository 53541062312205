// @flow
import React, { Fragment } from 'react';
import classNames from 'classnames';

import './LayoutRow.scss';

type Props = {
  left: any,
  right: any,
  reverseRow: boolean,
  hideColumn: Boolean,
};

const LayoutRow = ({ left, right, reverseRow, hideColumn } : Props) => {
  const layoutRow = classNames({
    'layoutRow': true,
    'layoutRow--standard': !reverseRow,
    'layoutRow--reverseRow': reverseRow,
    'layoutRow--hideColumn': hideColumn,
  });

  return (
    <div className={layoutRow}>
      <div className="layoutRow_rowPrimary">
        {left}
      </div>
      <Fragment>
        {right}
      </Fragment>
    </div>
  )
}

export default LayoutRow;
