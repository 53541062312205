// Global validation file
import DateUtil from '../Utils/DateUtil';
import moment from 'moment';

const VALID_ACCOUNT_NUMBER_LENGTH = 9;

export const validateAccountNumber = (accountNumber: any): boolean => {
  let lengthOfAccountNumber =
    typeof accountNumber !== "undefined" && accountNumber !== null
      ? accountNumber.length
      : 0;
  return lengthOfAccountNumber == VALID_ACCOUNT_NUMBER_LENGTH;
};

const validateBBAccountNumber = (number: string): boolean => {
  const regexp = new RegExp(/^[A-Za-z0-9]{2,9}$/);
  return number && regexp.test(number);
};

export const validateEmailAddress = (email: any): boolean => {
  const regexp = new RegExp(
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  );
  return (
    email !== null &&
    email !== undefined &&
    email !== "" &&
    regexp.test(email.trim())
  );
};

export const validatePassword = (password: any): boolean => {
  const regexp = new RegExp(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[\S]{8,}$/);
  return password !== undefined && regexp.test(password) && password !== "";
};

export const validateDate = (minDate, maxDate, date, handleError) => {
  let isInValid = false;
  isInValid = date === null || isInValid;
  isInValid =
    minDate && date
      ? validateFirstDateBeforeSecond(date, minDate) || isInValid
      : isInValid;
  isInValid =
    maxDate && date
      ? validateFirstDateBeforeSecond(maxDate, date) || isInValid
      : isInValid;
  return handleError(isInValid);
};

export const validateFirstDateBeforeSecond = (first, second) => {
  return first.getTime() < second.getTime();
};

const validatePhoneNumber = (phone: string): boolean => {
  const regexp = new RegExp(
    /(^02(0|1|2|5|7|8|9)[0-9]{6,8}$)|(^0(3|4|6|7|9)[0-9]{7}$)|(^0(3|4|6|7|9)[0-9]{7}$)|(^0(800|508)[0-9]{6,7}$)/
  );
  return phone && regexp.test(phone);
};

const validateDriverLicence = (licence: string): boolean => {
  const regexp = new RegExp(/^([A-Za-z]{2})([0-9]{6})$/);
  return licence && regexp.test(licence);
};

const validateCardVersionNumber = (number: string): boolean => {
  const regexp = new RegExp(/^[0-9]{3}$/);
  return number && regexp.test(number);
};

const validateName = (name, setValue) => {
  if (name && name.length > 0) {
    const onlyLetters = name
      .replace(/[\"\~`!@#$%^&()_={}[\]:;,.<>+\/?]+|\d+|^\s+$/g, "")
      .replace(/\s+/gi, " ");
    const uppercaseFirstLetter =
      onlyLetters && onlyLetters[0]
        ? onlyLetters[0].toUpperCase() + onlyLetters.substring(1)
        : onlyLetters;
    setValue(uppercaseFirstLetter);
  } else {
    setValue(name);
  }
};

export const validateDateOfBirth = (date, setValue) => {
  if (date) {
    let day = date.day && date.day.replace(/\D/g,'');
    let month = date.month && date.month.replace(/\D/g,'');
    let year = date.year && date.year.replace(/\D/g,'');
    if (day) {
      if (day > 31 || day === '00') {
        day = day.substr(0, 1);
      } else if (day.length === 1 && day > 3) {
        day = '0' + day ;
      }
    }
    if (month) {
      if (month.length === 1) {
        month = month > 1 ? "0" + month : month;
      }
      if (month.length > 1) {
        const days = moment(month, "MM").daysInMonth();
        if (day > days || month > 12 || month === '00') {
          month = month.substr(0, 1);
        }
      }
    }
    const isFebruary = month === "02";
    if (year && year.length === 4 && isFebruary && day > 28) {
      const isLeapYear = year % 4 == 0;
      if (day > 28 && !isLeapYear) {
        year = year.substr(0, 3); 
      }
    }
    if (setValue) {
      setValue({'day': day, 'month': month, 'year': year});
    }
  } else {
    setValue("");
  }
}

const validateStreetNumber = (value: string): boolean => {
  const regexp = new RegExp(/^\d+(\/[a-zA-Z0-9'-]*)?$/);
  return value && regexp.test(value);
};

const validatePostcode = (value: string): boolean => {
  const regexp = new RegExp(/^\d{4}$/);
  return value && regexp.test(value);
};

export const validate = (e, setValue, validationType, touched = false) => {
  e.preventDefault();
  let value = e.target.value;
  let regexPattern = "";
  let hasError = false;
  switch (validationType) {
    case "price":
      regexPattern = /^[0-9]\d*(\.\d{0,2})?$/;
      if (value == "") setValue(value);
      if (touched && value >= 1) {
        hasError = false;
      }
      if (touched && value < 1) {
        hasError = true;
      }

      if (regexPattern && regexPattern.test(value)) setValue(value);

      return hasError;
    case "accountNumber":
      regexPattern = /(\d{2}-\d{4}-\d{7}-\d{2,3})/;
      setValue(e.target.value);
      if (touched && regexPattern.test(e.target.value)) {
        hasError = false;
      }
      // return true;
      if (touched && !regexPattern.test(e.target.value)) {
        hasError = true;
      }

      if (regexPattern && regexPattern.test(value)) setValue(value);

      return hasError;
    case "phoneNumber":
      const phoneNumber = e.target.value;
      if (phoneNumber) {
        hasError = touched && !validatePhoneNumber(phoneNumber);
      }
      setValue(phoneNumber);
      return hasError;
    case "broadbandAccountNumber":
      const bbAccountNumber = e.target.value;
      if (bbAccountNumber) {
        hasError = touched && !validateBBAccountNumber(bbAccountNumber);
      }
      setValue(bbAccountNumber);
      return hasError;
    case "email":
      if (e.target.value) {
        hasError = touched && !validateEmailAddress(e.target.value);
      }
      setValue(e.target.value);
      return hasError;
    case "driverLicence":
      if (e.target.value) {
        hasError = touched && !validateDriverLicence(e.target.value);
      }
      setValue(e.target.value);
      return hasError;
    case "password":
      hasError = touched && !validatePassword(e.target.value);
      setValue(e.target.value);
      return hasError;
    case "aaSmartfuel":
      regexPattern = /(\d{4}\s\d{4}\s\d{4}\s\d{4})/;

      setValue(e.target.value);
      if (touched && regexPattern.test(e.target.value)) {
        hasError = false;
      }      
      if (touched && !regexPattern.test(e.target.value)) {
        hasError = true;
      }

      if (regexPattern && regexPattern.test(value)) setValue(value);

      return hasError;
    case "cardVersionNumber":
      if (e.target.value) {
        hasError = touched && !validateCardVersionNumber(e.target.value);
      }
      setValue(e.target.value);
      return hasError;

    case "name":
      validateName(e.target.value, setValue);
      break;
    case "streetNumber":
      if (e.target.value) {
        hasError = touched && !validateStreetNumber(e.target.value);
      }
      setValue(e.target.value);
      return hasError;
    case "postcode":
      if (e.target.value) {
        hasError = touched && !validatePostcode(e.target.value);
      }
      setValue(e.target.value);
      return hasError;
    default:
      if (setValue) setValue(value);
      break;
  }
};
