// @flow
import React, { Fragment, useState, useEffect } from 'react';
import { useSpring, animated, config } from 'react-spring';
import classNames from 'classnames';
import Icon from '../Icon';
import Loading from '../Loading';
import { validate } from '../../Utils/ValidationUtil';
import './Input.scss';
import { Error, SelectedTick } from '../../Assets/Icons';


export type Props = {
  labelText: string,
  handleChange: () => void,
  value: string,
  disabled: boolean,
  iconType: string;
  iconHandleClick: () => {},
  name: string,
  placeholder: string,
  required: boolean,
  showSuccessIcon: boolean,
  showLoadingIcon: boolean,
  hideErrorIcon: boolean,
  theme: string,
  validationType: string,
  maxLength?: number,
  handleError: () => {},
  inputProps: any,
  hasError: Boolean,
  errorMessage: string,
  invalidMessage: string,
  noPriceStyles: boolean,
  hint?: any,
  validateItself: boolean,
  onKeyDown: () => {},
  onBlur: () => {},
};

const Input = (props: Props) => {
  const [placeholderState, setPlaceholderState] = useState('');
  const [isFocused, setIsFocused] = useState(false);
  const [touched, setTouched] = useState(false);
  const [isValid, setIsValid] = useState(typeof props.isValid !== 'undefined' ? props.isValid : false);
  const [inputRef, setInputRef] = useState(null);

  //trigger auto validation
  useEffect(() => {
    if (props.validateItself && !props.hasError && props.required && !props.value){
      setTouched(true);
      props.handleError(true);
    }
  }, [props.validateItself, props.value, props.handleError, props.required, props.hasError]);

  useEffect(() => {
    setIsFocused(props.value ? true : isFocused);
  }, [props.value]);

  useEffect(() => {
    props.handleError && !props.required && !props.invalidMessage && props.handleError(false);
  }, [props.required, props.handleError, props.invalidMessage]);

  // Set is valid
  useEffect(() => {
    if (typeof props.isValid === 'undefined') {
      if (props.value) {
        const fakeChangeEvent = {
          preventDefault: () => {},
          target: {value: props.value},
        };
        const isValid = !validate(fakeChangeEvent, () => {}, props.validationType, true);
        setIsValid(isValid);
      } else {
        setIsValid(false);
      }
    } else {
      setIsValid(props.isValid);
    }
  }, [props.isValid, props.value, props.validationType]);

  const animateInput = useSpring({
    opacity: !isFocused && props.labelText ? 0 : 1,
    config: config.stiff
  });
  const animateLabel = useSpring({
    opacity: isFocused ? 0.6 : 1,
    bottom: isFocused ? '24px' : '0px',
    fontSize: isFocused ? '14px' : '18px',
    config: config.stiff
  });

  const inputStyle = classNames({
    'inputContainer_input': true,
    'inputContainer_input--price': props.validationType == 'price' && !props.noPriceStyles,
  });

  const onChangeHandler = (event) => {
    props.handleError(validate(event, props.handleChange, props.validationType, touched));

    // Run autocomplete onchange event
    if (props.inputProps && props.inputProps.onChange) props.inputProps.onChange(event);
  }

  const onFocusHandler = (event) => {
    setIsFocused(true);
    setPlaceholderState(props.placeholder); 
    
    // Focus's the autocomplete
    if(props.inputProps && props.inputProps.onFocus) props.inputProps.onFocus(event);
    if(inputRef && inputRef.focus) inputRef.focus();
  }

  const onBlurHandler = (event) => {
    setTouched(true);
    if (props.onBlur) {
      props.onBlur();
    }
    setIsFocused(event.target.value && event.target.value !== '');
    setPlaceholderState('');
    if (props.validationType == 'price') {
      event.target.value = isNaN(parseFloat(event.target.value)) ? '' : parseFloat(event.target.value).toFixed(2);
    }
    if (validate(event, props.handleChange, props.validationType, true) || (props.required && !props.value)) {
      props.handleError(true);
    } else {
      props.handleError(false);
    }

    // Blur's the autocomplete
    if (props.inputProps && props.inputProps.onBlur) props.inputProps.onBlur(event);
  }

  const getInputRef = (input) => {
    setInputRef(input);
    if(typeof props.inputProps.ref === "object") {
      props.inputProps.ref.current = input;
    } else if(typeof props.inputProps.ref === "function") {
      props.inputProps.ref(input);
    }
  } 

  return (
    <Fragment>
      <div className="inputContainer" disabled={props.disabled}>
          {/* eslint-disable-next-line*/}
        {props.labelText && !props.hasError && (
        <animated.label
          style={animateLabel}
          id={props.name}
          name={props.name}
          htmlFor={props.name}
          className="inputContainer_label"
          onClick={() => onFocusHandler()}
        >
          {props.labelText}
        </animated.label>
        )}
        {/* eslint-disable jsx-a11y/label-has-for */}
        {props.hasError && (
          <label
            style={{ color: 'red', 
              opacity: 0.6,
              bottom: '24px',
              fontSize: '14px' }}
            id={props.name}
            htmlFor={props.name}
            className="inputContainer_label"
            onClick={() => onFocusHandler()}
          >
            {props.value && props.invalidMessage ? props.invalidMessage : props.errorMessage}
          </label>
        )}

        {props.validationType == 'price' && !props.noPriceStyles && (
        <span className="inputContainer_dollar">
        $
        </span>
        )}
        {(props.validationType == 'price' && props.noPriceStyles && isFocused)  && (
        <span className="inputContainer_dollar--noPriceStyle">
        $
        </span>
        )} 
        <animated.input
          id={props.name}
          name={props.name}
          className={inputStyle}
          type="text"
          value={props.value}
          placeholder={props.labelText ? placeholderState : props.placeholder}
          htmlFor={props.name}
          required={props.required}
          disabled={props.disabled}
          style={animateInput}
          // InputProps must be before input events as it 
          // contains events that will overide the events below
          // The inputProps have other fields needed so don't remove
          {...props.inputProps}
          onChange={e => onChangeHandler(e)}
          onFocus={e => onFocusHandler(e)}
          onBlur={e => onBlurHandler(e)}
          onKeyDown={props.onKeyDown}
          ref={props.inputProps && props.inputProps.ref 
            ? getInputRef
            : null
          }
          maxLength={props.maxLength}
          autoComplete="off"
        />
        {props.iconType && !props.hasError && (
          <div
            onClick={() => onFocusHandler()}
            onKeyDown={(e) => {
              if (e.key == 'Enter') onFocusHandler()
              }
            }
            role="button"
            tabIndex="0"
          >
            {!props.hasError && (<Icon icon={props.iconType} className="inputContainer_icon" /> )}
          </div>
        )}
        {props.showLoadingIcon && (<Icon name='loadingIcon' icon={<Loading />} className="inputContainer_icon--loading" />)}
        {!props.showLoadingIcon && props.hasError && !props.hideErrorIcon && (<Icon name='errorIcon' icon={<Error />} className="inputContainer_icon--error" /> )}
        {!props.showLoadingIcon && props.showSuccessIcon && isValid && (<Icon name='successIcon' icon={<SelectedTick />} className="inputContainer_icon--success" /> )}
      </div>
      {props.hint && <div className="inputContainer_hint">{props.hint}</div>}
    </Fragment>
  );
};

Input.defaultProps = {
  handleError: () => {},
  value: '',
}

export default Input;
