import React, { useState, useEffect, useRef } from "react"; 
import classNames from "classnames";
import Icon from '../Icon';
import Button from "../Button";
import ScrollContainer from 'react-indiana-drag-scroll';
import { LongArrowUp, LongArrowDown, WhiteCheck } from '../../Assets/Icons';
import NavigationDots from '../NavigationDots';
import ControlArrows from "../ControlArrows";
import './BroadbandCarousel.scss';

type Props = {
	planIndex: Number,
	data: [],
	isDiscount?: boolean,
	arrowsCustomStyle?: Object,
	onPress: () => {},
	constants?: {},
};
const BroadbandCarousel = (props: Props) => {
	const [itemChecked, setItemChecked] = useState(null);
	const [scrollIndex, setScrollIndex] = useState(0);
	const [dragScroll, setDragScroll] = useState(0);
	const [controlsScroll, setControllsScroll] = useState(false);
	const step = 270;

	useEffect(() => {
		setItemChecked(props.planIndex);
	}, [props.planIndex]);

	const onItemSelect = (index) => {
		setItemChecked(itemChecked !== index ? index : null);
		if (itemChecked !== index) {
			props.onPress(props.data[index], index);
		} else {
			props.onPress({}, null);
		}
	}

	const scrollToElement = (params, isIndex) => {
		setControllsScroll(true);
		let index = 0;
		if (scrollIndex >= 0) {
			if (isIndex) {
				index = params;
			} else {
				index = scrollIndex + (params === 'right'
					? scrollIndex < (props.data.length - 1)
						? 1
						: 0
					: scrollIndex > 0
						? -1
						: 0
				);
			}
		}
		const name = props.data[index]?.Id;
		const element = document.getElementById(name);
		setScrollIndex(index);
		if (element) {
			element.scrollIntoView({behavior: "smooth", block: "nearest", inline: "start"});	
			if (index === (props.data.length-1)) {
				setScrollIndex(index);
			}
		}
	}

	const getPath = (index) => {
		return props.data[index].Id;
	}

	const onScrollStart = (event) => {
		setDragScroll(event);
	}

	const onScrollEnd = (event) => {
		if (!controlsScroll) {
			const scrollDifference = event - dragScroll;
			const scrollDitanse = Math.round(scrollDifference/step)* step;
			const numberOfSteps = Math.round(scrollDitanse / step);
			if (numberOfSteps >= 0) {
				setScrollIndex(scrollIndex + numberOfSteps);
			} else if (numberOfSteps < 0) {
				setScrollIndex(scrollIndex - Math.abs(numberOfSteps));
			}
			setControllsScroll(false);
		} else {
			setControllsScroll(false);
		}
	}

	const renderListItem = (list) => (
		<ScrollContainer className="broadbandCarousel" onStartScroll={(event) => onScrollStart(event)} onEndScroll={(event) => onScrollEnd(event)}>
			{
				list.map((item, index) => (
					<div key={index} className="broadbandCarousel_block" id={item.Id}>
						{!!item.Label && (
							<div className="broadbandCarousel_block_label">
								{item.Label}
							</div>
						)}
						<div className="broadbandCarousel_block_header">{item.Name}</div>
						<div className="broadbandCarousel_block_priceContainer">
							<div className="broadbandCarousel_block_priceContainer_amount">{props.isDiscount ? item.BundlePrice : item.OriginalPrice}</div>
							{props.isDiscount && (
								<div className="broadbandCarousel_block_priceContainer_originPrice">
									<div className="broadbandCarousel_block_priceContainer_originPrice_crossed">{item.OriginalPrice}</div>
									<div className="broadbandCarousel_block_priceContainer_originPrice_crossLine" />
								</div>
							)}
						</div>
						<div className="broadbandCarousel_block_speed">
							{item.DownloadSpeed && (
								<div className="broadbandCarousel_block_speed_speedContainer_first">
									<Icon
										icon={<LongArrowDown />}
										className="broadbandCarousel_block_speed_speedContainer_icon"
									/>
									<span className="broadbandCarousel_block_speed_speedContainer_text">
										{item.DownloadSpeed}
										{props.constants.download}
									</span>
								</div>
							)}
							{item.UploadSpeed && (
								<div className="broadbandCarousel_block_speed_speedContainer">
									<Icon
										icon={<LongArrowUp />}
										className="broadbandCarousel_block_speed_speedContainer_icon"
									/>
									<span className="broadbandCarousel_block_speed_speedContainer_text">
										{item.UploadSpeed}
										{props.constants.upload}
									</span>
								</div>
							)}
						</div>
						<Button
							className={classNames("broadbandCarousel_block_button", itemChecked === index ? "broadbandCarousel_block_button_buttonChecked" : "")}
							handleClick={() => onItemSelect(index)}
							id={`choose-${item.Name}-button`}
							text={
								itemChecked === index
									? (
										<Icon
											icon={<WhiteCheck />}
											size={28}
											className="broadbandCarousel_block_speed_speedContainer_icon"
										/>
									)
									: <div className="broadbandCarousel_block_button_buttonText">{props.constants.button}</div>
								}
						/>
					</div>
				))
			}
		</ScrollContainer>
	)

  return (
		<div className="broadbandCarouselContainer">
			{renderListItem(props.data)}
			{props.data.length > 1 && (
				<ControlArrows
					data={props.data}
					scrollIndex={scrollIndex}
					getPath={getPath}
					onClick={scrollToElement}
					customStyle={props.arrowsCustomStyle}
				/>
			)}
			<NavigationDots
				elements={props.data}
				index={scrollIndex}
				onClick={scrollToElement}
			/>
		</div>
  );
};

export default BroadbandCarousel;