// @flow
import * as React from 'react';

import './PlanCard.scss';

type Props = {
  banner: string,
  tag: string,
  content: React.Node,
};

const PlanCard = (props: Props) => (
  <div className='planCard'>
    <div className='planCard_ribbon'>
      <div className='planCard_ribbon_banner'>
        <div className='planCard_ribbon_banner_text'>
          {props.banner}
        </div>
      </div>
    </div>
    <div className='planCard_tag'>
      {props.tag}
    </div>
    <div className='planCard_content'>
      {props.content}
    </div>
  </div>
);

export default PlanCard;