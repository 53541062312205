// @flow
import React, { useEffect } from 'react'


import Button from '../Button';
import type { PossibleAddress } from "../IcpSelect/IcpSelectList";
import IcpSelectList from '../IcpSelect/IcpSelectList';
import Input from '../Input';
import Dropdown from '../Dropdown';

import './ServiceCard.scss';

type ServiceCardFieldType = "standard" | "button" | "address" | "link";
type Props = {
  fieldType: ServiceCardFieldType,
  title: string,
  value?: string,
  buttonTitle?: string,
  href?: string,
  target?: string,
  onButtonClick?: () => void;
  hasError?: boolean,
  isValid?: boolean,
  dropdownItems?: Array,
  showSuccessIcon?: boolean,
  selectedItem?: string,
  setShowAddressModal?: () => {},
  setShowIcpModal?: () => {},
  setSelectedItem?: () => {},
  addressList?: PossibleAddress[],
  onIcpSelect?: (selection: string) => void,
};

const AddressField = ({ addressList, onIcpSelect, ...props }: Props) => {

  useEffect(() => {
    if (addressList?.length === 1) {
      onIcpSelect(addressList[0].icp ?? addressList[0].value);
    }
  }, [addressList]);

  const helperText = props.setShowAddressModal || props.setShowIcpModal ? (
    <div className="serviceCardField_rightColumn_manual">
      Not right?{" "}
      {props.setShowAddressModal ? <div className="serviceCardField_rightColumn_manual_link" onClick={props.setShowAddressModal}>Manually enter address</div> : null}
      {props.setShowAddressModal && props.setShowIcpModal ? " or " : null}
      {props.setShowIcpModal ? <div className="serviceCardField_rightColumn_manual_link" onClick={props.setShowIcpModal}>Enter a different ICP</div> : null}
    </div>
  ) : null;

  return (
    <div className="serviceCardField">
      <div className="serviceCardField_left">{props.title}</div>
      <div className="serviceCardField_rightColumn">
        <div className="serviceCardField_rightColumn_dropdown">
          {
            addressList?.length > 1
              ? <IcpSelectList
                possibleAddresses={addressList}
                listOnly
                onClick={onIcpSelect}
              />
              : <Input
                value={props.value}
                disabled
              />
          }
        </div>
        {helperText}
      </div>
    </div>
  );
};

const ServiceCardField = (props: Props) => {
  const { selectedItem, setSelectedItem } = props;

  // Standard field builder
  const buildStandardField = () => {
    return (
      <div className="serviceCardField">
        <div className="serviceCardField_left">{props.title}</div>
        <div className="serviceCardField_right">
          <div className="serviceCardField_right_input">
            <Input
              handleChange={() => {}}
              value={props.value}
              disabled
              isValid={props.isValid}
              showSuccessIcon={props.showSuccessIcon}
              hasError={props.hasError}
            />
          </div>
        </div>
      </div>
    );
  }

  // Button field builder
  const buildClipboardButtonField = () => {
    return (
      <div className="serviceCardField">
        <div className="serviceCardField_left">{props.title}</div>
        <div className="serviceCardField_right">
          <div className="serviceCardField_right_input">
            <Input
              handleChange={() => {}}
              value={props.value}
              disabled
              hasError={props.hasError}
            />
          </div>
          {!props.hasError && <Button
            className="serviceCardField_right_button"
            type="button"
            text={props.buttonTitle}
            handleClick={() => {navigator.clipboard.writeText(props.value)}}
            handleValidationClick={() => {}}
            primaryOnLight
          />}
        </div>
      </div>
    );
  }

  const buildButtonField = () => {
    return (
      <div className="serviceCardField">
        <div className="serviceCardField_left">{props.title}</div>
        <div className="serviceCardField_right">
          <div className="serviceCardField_right_input">
            <Input
              handleChange={() => {}}
              value={props.value}
              disabled
              hasError={props.hasError}
            />
          </div>
          {!props.hasError && <Button
            className="serviceCardField_right_button"
            type="button"
            primaryOnLight
            dense
            href={props.href}
            target={props.target}
            text={props.buttonTitle}
            handleClick={() => {
              if (!props.href && props.onButtonClick) {
                props.onButtonClick();
              }
            }}
            handleValidationClick={() => {}}
          />}
        </div>
      </div>
    );
  }

  const buildDropdownField = () => {
    return (
      <div className="serviceCardField">
        <div className="serviceCardField_left">{props.title}</div>
        <div className="serviceCardField_right">
          <Dropdown
            items={props.dropdownItems}
            selectedItem={selectedItem}
            handleSelectedItem={setSelectedItem}
            labelText=""
            handleError={() => {}}
            errorMessage=""
            hasError={false}
            required
            isServiceCard
          />
        </div>
      </div>
    );
  }


  // Build correct field type
  switch (props.fieldType) {
    case "address":
      return <AddressField {...props} />;
    case "link":
      return buildButtonField();
    case "button":
      return buildClipboardButtonField();
    case "standard":
      return buildStandardField();
    case "dropdown":
      return buildDropdownField();
    default:
      return null;
  }
};

export default ServiceCardField;
