// @flow
import React, { useState, useEffect, useMemo } from 'react'
import { useSpring, animated } from 'react-spring'
import classNames from 'classnames';
import isEqual from 'lodash/isEqual';
import { useMemoCompare } from '../../Utils/hooks'
import Icon from '../Icon';
import Card from '../Card';
import ExpansionPanel from '../ExpansionPanel';
import { Block } from '../../Assets/Icons';
import Checkbox from '../Checkbox';

import './ExpandableCard.scss';

type Props = {
  headerContent: {
    cardTitle: string,
    cardContent: string,
    cardLogo: string,
    cardIcon: string,
    cardLink: boolean,
    cardIsSelected: boolean,
    disabled: boolean,
    mainIcon: String,
    withCheckbox?: boolean,
  },
  content?: any,
  handleClick: () => {},
  isOpen: Boolean,
  name: string,
  disabled: boolean,
  onCheckboxChange: () => {},
  checked: boolean,
  classes?: {
    card?: {},
    infoContainer?: {},
    checkbox?: {},
    icon?: {},
    title?: {},
  },
};

const ExpandableCard = (props: Props) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const arrowDefaultRotation = props.content ? '0deg' : '270deg';
  const [arrowAnimation, setArrowAnimation] = useSpring(() => ({ transform: `rotate(${arrowDefaultRotation})` }));

  // Note: These caches ensure that we use a more expensive deep equality check to determine if these non-primitives
  //       data structures have actually changed their contents so the useMemo below does not unnecessarily re-run.
  const classesCache = useMemoCompare(props.classes, isEqual);
  const headerContentCache = useMemoCompare(props.headerContent, isEqual);
  const classes = useMemo(() => {
    const c = {
      card: classNames({
        'expandableCard': true,
        'expandableCard--closed': !isExpanded,
        'expandableCard--disabled': props.disabled,
        'expandableCard--selected': headerContentCache.cardIsSelected && !props.content && !headerContentCache.withCheckbox,
        'expandableCard--selectedWithHover': headerContentCache.cardIsSelected && props.content && !headerContentCache.withCheckbox,
      }),
      infoContainer: classNames({
        "expandableCard_accountInfoContainer": true,
        ...(classesCache?.infoContainer || {}),
      }),
      checkbox: classNames({
        "expandableCard_checkbox": true,
        ...(classesCache?.checkbox || {}),
      }),
      icon: classNames({
        "expandableCard_mainIcon": !headerContentCache.withCheckbox,
        "expandableCard_mainIconWithCheckbox": headerContentCache.withCheckbox,
        ...(classesCache?.icon || {}),
      }),
      title: classNames({
        'title': true,
        "expandableCard_headerContainer": headerContentCache.mainIcon && !headerContentCache.withCheckbox,
        "expandableCard_headerContainerWithCheckbox": headerContentCache.mainIcon && headerContentCache.withCheckbox,
        ...(classesCache?.title || {})
      }),
    };
    return c;
  }, [classesCache, headerContentCache, props.disabled, props.content]);

  useEffect(() => {
    if (props.disabled) {
      setIsExpanded(false);
    } else if (typeof props.isOpen !== 'undefined') {
      setIsExpanded(props.isOpen);
    }
  }, [props.isOpen, props.disabled, props.checked]);

  setArrowAnimation({ transform: isExpanded ? 'rotate(180deg)' : `rotate(${arrowDefaultRotation})` });

  const handleCardClick = () => {
    if (!props.disabled && (props.checked !== undefined && props.checked)) {
      props.headerContent.cardLink ? props.handleClick() : setIsExpanded(!isExpanded);
    }
    if (!props.disabled) {
      props.handleClick && props.handleClick();
    }
  };

  return (
    <Card name={props.name} className={classes.card}>
      <div
        className="expandableCard_closedContainer"
        role="menuitem"
        onClick={handleCardClick}
        onKeyDown={(e) => e.key === 'Enter' && handleCardClick()}
        tabIndex={0}
      >
        <div className={classes.infoContainer}>
          {props.headerContent.withCheckbox ? (
            <Checkbox
              className={classes.checkbox}
              onChange={props.onCheckboxChange}
              checked={props.checked}
            />
          ): null}
          {props.headerContent.mainIcon ? (
            <Icon icon={props.headerContent.mainIcon} className={classes.icon} />
          ) : null}
          <div className={classes.title}>
            <div className={props.headerContent.withCheckbox ? "expandableCard_headerWithCheckbox" :"expandableCard_accountHeader"}>
              {props.headerContent.cardTitle}
            </div>
            {props.headerContent.withCheckbox ? null :
              <ExpansionPanel isExpanded={!isExpanded}>
              <div className="expandableCard_accountText">
                {props.disabled ? null : props.headerContent.cardContent}
              </div>
            </ExpansionPanel>
            }
          </div>
        </div>
        <div className="expandableCard_iconContainer">

          {props.headerContent.cardLogo && (
            <Icon icon={props.headerContent.cardLogo} className="expandableCard_iconBank" />
          )}

          <animated.div style={arrowAnimation} className="expandableCard_iconArrow">
            <Icon
              icon={props.disabled ? <Block/> : props.headerContent.cardIcon}
              className="expandableCard_icon"
            />
          </animated.div>

        </div>
      </div>
      <ExpansionPanel isExpanded={isExpanded}>
        <div className="expandableCard_mainContent">
          {props.content && (
            props.content
          )}
        </div>

      </ExpansionPanel>
    </Card>
  )
}

export default ExpandableCard;
