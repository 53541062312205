// @flow
import * as React from 'react';
import Icon from '../Icon';

type Props = {
  icon: React.Node,
  title: string,
  name: string,
  children: React.Node,
};

const StepListItem = (props: Props) => {
  return (
    <li name={props.name} className="stepList_item">
      <span className="stepList_item_iconCon">
        <Icon icon={props.icon} className="stepList_item_icon" />
      </span>
      <div className="stepList_item_content">
        <h3>{props.title}</h3>
        {props.children}
      </div>
    </li>
  );
};

export default StepListItem;
