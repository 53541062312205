// @flow
import React from 'react';
import cx from 'classnames';

import Icon from '../Icon';
import { ArrowDown } from '../../Assets/Icons';

import './styles.scss';

type Props = {
  expandLabel: string,
  collapseLabel: string,
  expanded: boolean,
  onToggle: () => void,
  className: string,
  tabletInline?: boolean,
  mobileInline?: boolean,
};

const ToggleButton = ({ expandLabel, collapseLabel, expanded, onToggle, mobileInline, tableInline, className }: Props) => (
  <span
    className={cx('toggleButton', className, {
      'toggleButton--expanded': expanded,
      'toggleButton--tabletInline': tableInline,
      'toggleButton--mobileInline': mobileInline,
    })}
    onClick={onToggle}
  >
    <span className="toggleButton_collapsedLabel">{expandLabel}</span>
    <span className="toggleButton_expandedLabel">{collapseLabel}</span>
    <Icon icon={<ArrowDown/>} className="toggleButton_arrowDownIcon" />
  </span>
);

export default ToggleButton;
