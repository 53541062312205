// @flow
import * as React from 'react';
import classNames from 'classnames';

import './Card.scss';

type Props = {
  name: string,
  children: React.Node,
};

const Card = (props: Props) => (
  <div name={props.name} className={classNames('card', props.className)}>
    {props.children}
  </div>
);

export default Card;
