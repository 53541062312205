// @flow
import React, { useState, useEffect, useRef } from 'react';

import Input, { type Props as InputProps } from '../Input';
import { useDebounce } from '../../Utils/hooks';

export type Props = {
  ...InputProps,
  isVerified: boolean,
  handleVerified: () => void,
  handleVerificationError: () => void,
  formatFn: (value: any) => string,
  verifyFn: () => Promise<boolean>,
  toBeVerifiedFn: () => boolean,
};

const defaultProps = {
  formatFn: value => value,
};

function VerifiedInput(props: Props) {
  const [value, setValue] = useState(props.value);
  const [cursor, setCursor] = useState(props.value ? props.value.length: 0);
  const [hasError, setHasError] = useState(props.hasError);
  const [isVerified, setIsVerified] = useState(props.isVerified);
  const [isVerifying, setIsVerifying] = useState(false);
  const [verifiedValue, setVerifiedValue] = useState(null);
  const inputRef = useRef(null);

  function verify (value: string) {
    if (value === verifiedValue) {
      // It's verified if the value is the same as the last verified value
      setIsVerifying(false);
      setHasError(false);
      setIsVerified(true);
    } else if (props.toBeVerifiedFn(value)) {
      // If is to be verified, verify asynchronously
      setIsVerifying(true);
      props.verifyFn(value, props)
        .catch(() => {
          // api call failed
          setIsVerified(false);
          setHasError(true);
          setVerifiedValue(null);
          props.handleVerificationError();
        })
        .then((isValid) => {
          setHasError(!isValid);
          setIsVerified(isValid);
          setVerifiedValue(isValid ? value : null);
        })
        .finally(() => {
          setIsVerifying(false);
        });
    } else {
      // reset loading indicator, if not to be verified
      setIsVerifying(false);
    }
  }

  // Set value state from prop
  useEffect(() => {
    setValue(props.value);
  }, [props.value]);

  // Set has error from prop
  useEffect(() => {
    setHasError(props.hasError);
  }, [props.hasError]);

  // Show loading indicator if the value is ready to be verified.
  // We are doing this here and not in debounced because otherwise there would be a delay.
  useEffect(() => {
    if (value === verifiedValue) {
      setHasError(false);
      setIsVerified(true);
      setIsVerifying(false);
    } else if (props.toBeVerifiedFn(value)) {
      setIsVerifying(true);
    }
  }, [value]);

  // Debounce verification API call by 500ms
  const debouncedValue = useDebounce(value, 500);

  useEffect(() => {
    verify(debouncedValue);
  }, [debouncedValue]);

  const handleChange = (value) => {
    const newValue = props.formatFn(value);
    setValue(newValue);
    props.handleChange && props.handleChange(newValue);
  };

  const handleBlur = () => {
    verify(value);
  };

  // Remember cursor position
  const handleCursor = (e) => {
    setCursor(props.formatFn(e.target.value.substr(0, e.target.selectionStart)).length);
  };

  // ... and restore it
  useEffect(() => {
    if (inputRef && inputRef.current) {
      inputRef.current.setSelectionRange(cursor, cursor);
    }
  }, [cursor, value]);

  const handleError = (hasError) => {
    setHasError(hasError);
    setIsVerified(false);
    props.handleError && props.handleError(hasError);
  };

  // Fire verified event handler if isVerified changes.
  useEffect(() => {
    props.handleVerified && props.handleVerified(isVerified);
  }, [isVerified]);

  return (
    <Input
      {...props}
      inputProps={{
        ...(props.inputProps || {}),
        ref: inputRef,
        onBlur: handleBlur,
        onChange: handleCursor,
      }}
      value={value}
      handleChange={handleChange}
      handleError={handleError}
      hasError={hasError}
      showSuccessIcon={!hasError}
      showLoadingIcon={isVerifying}
      isValid={isVerified}
    />
  );
}

VerifiedInput.defaultProps = defaultProps;

export default VerifiedInput;
