// @flow
import React from 'react';
import classNames from 'classnames';
import Icon from '../Icon';
import Loading from '../Loading';

import './Button.scss';

type Props = {
    handleClick: () => void,
    handleValidationClick: () => void,
    name: string,
    text: string,
    secondaryText: string,
    theme: string,
    type: string,
    disabled: boolean,
    dense?: boolean,
    primaryOnLight: boolean,
    primaryOnDark: boolean,
    primaryBlack: boolean,
    inheritCase: boolean,
    darkBorder: boolean,
    icon: string,
    isLoading: boolean,
    className?: string,
    href?: string,
    target?: string,
    id?: string,
    ref?: node,
    customStyle?: Object,
    onMouseEnter: () => void,
    onMouseLeave: () => void,
  };

const Button = (props: Props) => {
  const buttonClass = classNames(props.className, {
    'button': true,
    'button--ghost': props.theme == 'ghost',
    'button--purple': props.theme == 'purple',
    'button--primaryBlack': props.primaryBlack,
    'button--primaryOnDark': props.primaryOnDark,
    'button--primaryOnLight': props.primaryOnLight,
    'button--darkBorder': props.darkBorder,
    'button--toggleConfirm': props.theme == 'toggleConfirm',
    'button--toggleCancel': props.theme == 'toggleCancel',
    'button--inheritCase': props.inheritCase,
    'button--href': props.href,
    'button--primaryOnLight--disabled': props.primaryOnLight && props.disabled && props.handleValidationClick,
    'button--dense': props.dense,
  });

  if (props.href) {
    return (
      <a id={props.id} className={buttonClass} href={props.href} target={props.target} onClick={props.handleClick}>
        <span className='button__inner'>
          {props.icon && (
            <span>
              <Icon icon={props.icon} className="button_icon" />
            </span>
          )}
          {props.text}
        </span>
      </a>
    )
  }

  // const type = 'Primary' | 'Secondary' | 'Tertiary' | 'Disabled' | 'Loading'

  return (
    // eslint-disable-next-line react/button-has-type
    <button
      ref={props.buttonRef}
      id={props.id}
      name={props.name}
      disabled={props.disabled && !props.handleValidationClick}
      type={props.type}
      className={buttonClass}
      onClick={props.disabled && props.handleValidationClick ? props.handleValidationClick : props.handleClick}
      tabIndex={0}
      style={props.customStyle}
      onMouseEnter={props.onMouseEnter}
      onMouseLeave={props.onMouseLeave}
    >
      {props.icon && (
        <span>
          <Icon icon={props.icon} className="button_icon" />
        </span>
      )}

      {props.isLoading && (
        <div className="button_loader">
          <Loading isInContainer="true" />
        </div>
      )}

      {props.text}
      {props.secondaryText && <div className="button_secondaryText">{props.secondaryText}</div>}
    </button>
  );
};

Button.defaultProps = {
  type: "button",
  theme: 'reverseGhost',
};

export default Button;
