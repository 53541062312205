import React, { useState, useEffect } from 'react';
import deburr from 'lodash/deburr';
import Autosuggest from 'react-autosuggest';
import Paper from '@material-ui/core/Paper';
import classNames from 'classnames';

import { withStyles } from '@material-ui/core/styles';

import './AutoSuggestInput.scss';
import customInput from './CustomInput';
import suggestion from './Suggestion';

// Material UI styles
const styles = theme => ({
  root: {
    height: 60,
    flexGrow: 1,
  },
  container: {
    position: 'relative',
  },
  suggestionsContainerOpen: {
    position: 'absolute',
    zIndex: 1,
    marginTop: theme.spacing.unit,
    left: 0,
    right: 0,
  },
  suggestion: {
    display: 'block',
  },
  suggestionsList: {
    margin: 0,
    padding: 0,
    listStyleType: 'none',
  },
  divider: {
    height: theme.spacing.unit * 2,
  },
});

type Props = {
  disabled: boolean,
  handleChange: () => void,
  value: any,
  placeholder: string,
  required: boolean,
  labelText: string,
  options: [],
  maxLength?: number,
  containerClassName?: string,
  validateItself: boolean,
};
function IntegrationAutosuggest(props: Props) {

  const [suggestions, setSuggestions] = useState([]);

  const handleSuggestionsFetchRequested = ({ value }) => {
    setSuggestions(getSuggestions(value, props.options))
  };

  const handleSuggestionsClearRequested = () => {
    setSuggestions([]);
  };

  const valueChangedHandler = (event, { newValue }) => {
    props.handleChange(newValue);
  };

  function getSuggestions(value, suggestions) {
    const inputValue = deburr(value.trim()).toLowerCase();
    const inputLength = inputValue.length;
    let count = 0;
    const filterSuggestions = inputLength === 0
      ? []
      : suggestions.filter(suggestion => {
          const keep =
            count < 10 && suggestion.name.slice(0, inputLength).toLowerCase() === inputValue;

          if (keep) {
            count += 1;
          }

          return keep;
        });
    return filterSuggestions;
  }

  const { classes } = props;
  const autosuggestProps = {
    suggestions: suggestions,
    onSuggestionsFetchRequested: handleSuggestionsFetchRequested,
    onSuggestionsClearRequested: handleSuggestionsClearRequested,
    renderSuggestion: suggestion,
  };
  const autoSuggestClassName = classNames(classes.root, props.containerClassName);

  return (
      <div className={autoSuggestClassName}>
        <Autosuggest
          {...autosuggestProps}
          inputProps={{
            classes,
            value: props.value,
            onChange: valueChangedHandler,
          }}
          theme={{
            container: classes.container,
            suggestionsContainerOpen: classes.suggestionsContainerOpen,
            suggestionsList: classes.suggestionsList,
            suggestion: classes.suggestion,
          }}
          getSuggestionValue={(suggestion) => suggestion.name}
          renderInputComponent={(inputProps) => customInput(inputProps, props)}
          renderSuggestionsContainer={options => (
            <Paper {...options.containerProps} square>
              {options.children}
            </Paper>
          )}
        />
      </div>
  );
}

export default withStyles(styles)(IntegrationAutosuggest);
