// @flow
import React from 'react';

import classNames from 'classnames';

import './LayoutColumn.scss';

type Props = {
  top: any,
  bottom: any,
  hideColumn: boolean,
  reverseColumn: boolean,
  hideBottom: boolean,
  reverseTabletWidth: boolean,
  topIsSection: boolean,
  bottomIsImageContainer: boolean,
  fullHeight: boolean,
}

const LayoutColumn = ({ top, bottom, hideBottom, hideColumn, reverseColumn, reverseTabletWidth, topIsSection, bottomIsImageContainer, fullHeight }: Props) => {
  const layoutColumn = classNames({
    'layoutColumn': true,
    'layoutColumn--reverseColumn': reverseColumn,
    'layoutColumn--hideColumn': hideColumn,
    'layoutColumn--hideBottom': hideBottom,
    'layoutColumn--reverseTabletWidth': reverseTabletWidth,
    'layoutColumn--fullHeight': fullHeight,
  });

  const renderTop = () => {
    if (topIsSection) {
      return (
        <section className="layoutColumn_topContainer" >
          {top}
          <div className='layoutColumn_colBackground' />
        </section>
      );
    }
    
    return (
      <div className='layoutColumn_topContainer'>
        {top}
      </div>
    )
  };
  
  const renderBottom = () => {
    if (bottomIsImageContainer) {
      return (
        <div className="layoutColumn_bottomImageContainer">
          {bottom}
        </div>
      )
    }

    return (
      <div className="layoutColumn_bottomContainer">
        {bottom}
      </div>
    );
  };

  return (
    <div className={layoutColumn}>
      {renderTop()}    
      {renderBottom()}
    </div>
  )
}

export default LayoutColumn;
