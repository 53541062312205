// @flow
import React from 'react';
import axios from 'axios';
import VerifiedInput, { type Props as VerifiedInputProps } from '../VerifiedInput';
import { type ApiProps } from '../types';

type Props = {
  ...VerifiedInputProps,
  api: ApiProps,
};

const minLength = 16;
const maxLength = 19;

/**
 * Formats Bank account number.
 * @param value {string}
 * @return {string}
 */
function formatBankAccountNumber (value: string): string {
  return value
    .replace(/[^\d-]/g, "")
    .replace(/[-]?/g, "")
    .replace(/(^\d{2})/g, "$1-")
    .replace(/(^\d{2}-\d{4})/g, "$1-")
    .replace(/(^\d{2}-\d{4}-\d{7})/g, "$1-")
    .replace(/[-]$/g, "");
}

/**
 * Verifies the bank account number using API.
 * @param bankAccountNumber
 * @param api
 * @return {Promise<boolean>}
 */
async function verifyBankAccountNumber (bankAccountNumber: string, { api }: Props): boolean {
  const url = api.baseUrl + '/direct-debits/validate/' + bankAccountNumber;
  const config = {
    headers: {
      'Content-Type': 'application/json',
      'x-api-key': api.key,
    }
  };
  const result = await axios(url, config);
  return Boolean(result.data && result.data.isValid);
}

/**
 * Returns true, if value is ready to be verified.
 * @param value
 * @returns {string|boolean}
 */
function isToBeVerified(value: string): boolean {
  return value && value.length >= minLength && value.length <= maxLength;
}

function BankAccountInput(props: Props) {
  return (
    <VerifiedInput
      {...props}
      placeholder="00-0000-0000000-000"
      maxLength={maxLength}
      minLength={minLength}
      validationType="accountNumber"
      formatFn={formatBankAccountNumber}
      verifyFn={verifyBankAccountNumber}
      toBeVerifiedFn={isToBeVerified}
    />
  );
}

export default BankAccountInput;
