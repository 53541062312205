// @flow
import React, { useState } from 'react';
import classNames from 'classnames';

import './YesNoButtonGroup.scss';

type Props = {
  name: string,
  onChange: () => void
};

const options = [
  {
    label: 'Yes',
    value: true
  },
  {
    label: 'No',
    value: false
  }
];

const YesNoButtonGroup = (props: Props) => {
  const [value, setValue] = useState(-1);

  const handleClick = value => () => {
    setValue(value);
    props.onChange(value);
  };

  return (
    <div className='yesNoButtonGroup'>
      <div className='yesNoButtonGroup_container'>
        <div className='yesNoButtonGroup_wrap'>
          {options.map(option => (
            <span
              id={props.name ? props.name + option.label : option.label}
              name={props.name ? props.name + option.label : option.label}
              key={option.label}
              className={classNames('yesNoButtonGroup_wrap_item', {
                'yesNoButtonGroup_wrap_item--checked': value === option.value
              })}
              onClick={handleClick(option.value)}
            >
              {option.label}
            </span>
          ))}
        </div>
      </div>
    </div>
  );
};

export default YesNoButtonGroup;
