import React, { useState, useEffect } from "react";
import classNames from "classnames";
import './NavigationDots.scss';

type Props = {
	index: Number,
	elements: Array,
	onClick: () => {},
};
const NavigationDots = (props: Props) => {
	return (
		<div className="navigationDots">
			{props.elements.map((item, index) => (
				<div
					className="navigationDots_container"
					key={index}
					onClick={() => props.onClick(index, true)}
				>
					<div className={classNames("navigationDots_container_dot", props.index === index ? "navigationDots_container_dot--selected" : "" )}>
					</div>
				</div>
			))}

		</div>
	);
};

export default NavigationDots;