// @flow
import React from 'react';

type Props = {
    html: string,
};

const HTML = (props: Props) => (
    <div dangerouslySetInnerHTML={{__html: props.html}} />
);

export default HTML;
