// @flow
import React, { useState, useEffect } from 'react';
import Input from '../Input';
import Button from "../Button";
import { Tick } from '../../Assets/Icons';
import Loading from '../Loading';
import classNames from 'classnames';
import './PromoCode.scss';

type Props = {
  placeholder: string,
  buttonText: string,
  buttonTextHover?: string,
  buttonTextApplied?: string,
  maxLength: number,
  code: string,
  codeSubmitted: boolean,
  hasError?: boolean,
  isCodeValidating?: boolean,
  buttonDisabled?: boolean,
  errorMessage?: string,
  invalidMessage?: string,
  successMessage?: string,
  setCode: () => void,
  setCodeSumbitted: () => void,
  validateItself: () => {},
  handleError: () => {},
};

const PromoCode = (props: Props) => {
  const [hover, setHover] = useState(false);
  const {
    placeholder,
    buttonText,
    buttonTextHover,
    buttonTextApplied,
    maxLength,
    code,
    hasError,
    errorMessage,
    successMessage,
    invalidMessage,
    codeSubmitted,
    setCode,
    setCodeSumbitted,
    validateItself,
    handleError,
    isCodeValidating,
    buttonDisabled,
  } = props;
  return (
    <div className='promoCode'>
      {successMessage && (
        <div className="promoCode_banner">
          {successMessage? successMessage : ""}
        </div>
      )}
      <div className='promoCode_field'>
        <Input
          name="promoCode"
          className='promoCode_field_input'
          value={code}
          handleChange={setCode}
          maxLength={maxLength}
          labelText={placeholder}
          handleError={handleError}
          hasError={hasError}
          errorMessage={errorMessage}
          invalidMessage={invalidMessage}
          validateItself={validateItself}
          hideErrorIcon
        />
        <Button
          className={classNames('promoCode_field_button', codeSubmitted && 'promoCode_field_button_submitted')}
          text={codeSubmitted
            ? buttonTextApplied
            : hover
              ? buttonTextHover
              : buttonText
          }
          type="button"
          handleClick={() => setCodeSumbitted(!!code)}
          theme="purple"
          icon={codeSubmitted ? <Tick /> : null}
          onMouseEnter={() => {
            setHover(true);
          }}
          onMouseLeave={() => setHover(false)}
          disabled={buttonDisabled || codeSubmitted}
        />
        {isCodeValidating && (
          <div className="promoCode_loader">
            <Loading customstyle={{ minWidth: '100%', minHeight: '0' }} />
          </div>
        )} 
      </div>
    </div>
  );
};

export default PromoCode;