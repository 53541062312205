// @flow
import Decimal from 'decimal.js';
import type {
  APIUsageType,
  APIServiceRate,
  APIRate,
  APIRates,
  ServiceRate,
  Rate,
  Rates,
} from './types';
import {
  USAGE_TYPE_STANDARD,
  USAGE_TYPE_LOW,
  RATE_UNIT_TYPE_DAY,
  RATE_UNIT_TYPE_WATT,
  SERVICE_ID_ELECTRICITY,
  SERVICE_ID_PIPED_GAS,
} from './constants';

/**
 * Returns true, if has multiple rates for defined usage type.
 * @param usageType {APIUsageType}
 * @param rates {Rates}
 * @return {boolean}
 */
export function hasMultipleRates(usageType: APIUsageType, rates: Rates): boolean {
  return Boolean(
    (usageType === USAGE_TYPE_LOW && (rates[0].lowRates && rates[0].lowRates.length > 3)) ||
    (usageType === USAGE_TYPE_STANDARD && (rates[0].standardRates && rates[0].standardRates.length > 3))
  );
}

/**
 * Returns true, if rates are valid.
 * @param rates
 * @return {boolean|*}
 */
export function areRatesValid(rates: Rates): boolean {
  if (!rates || !rates.length) {
    return false;
  }
  const hasLowRates = rates.find(rate => rate.lowRates && rate.lowRates.length);
  const hasStandardRates = rates.find(rate => rate.standardRates && rate.standardRates.length);
  return Boolean(hasLowRates || hasStandardRates);
}

/**
 * Takes APIRate unit and returns the rate unit type.
 * @param apiRateUnit {APIServiceRate.Unit}
 * @return {ServiceRate.type}
 */
function getRateType(apiRateUnit: APIServiceRate.Unit): ServiceRate.type {
  if (apiRateUnit) {
    return (apiRateUnit.includes('day') ? RATE_UNIT_TYPE_DAY : RATE_UNIT_TYPE_WATT);
  }
  return '';
}

/**
 * Formats rates value.
 * @param apiDisplayRate {APIServiceRate.DisplayRate}
 * @param apiRateUnit {APIServiceRate.Unit}
 * @return {ServiceRate.rate}
 */
function getFormattedRate(apiDisplayRate: APIServiceRate.DisplayRate, apiRateUnit: APIServiceRate.Unit): ServiceRate.rate {
  if (!apiRateUnit) return apiDisplayRate;
  const suffix = apiRateUnit.includes('cents') ? 'c' : '';
  return apiDisplayRate + suffix;
}

/**
 * Returns true if low user rate.
 * @param apiUserType {APIServiceRate.UserType}
 * @return {boolean}
 */
function isLowUserRate(apiUserType: APIServiceRate.UserType): boolean {
  return apiUserType === USAGE_TYPE_LOW;
}

/**
 * Returns true if standard user rate.
 * @param apiUserType {APIServiceRate.UserType}
 * @return {boolean}
 */
function isStandardUserRate(apiUserType: APIServiceRate.UserType): boolean {
  return apiUserType === USAGE_TYPE_STANDARD;
}

/**
 * Takes rates object returned from api and converts it to readable rates.
 * @param apiRates {APIRates}
 * @param labels
 * @return {Rates}
 */
export function getRates(apiRates: APIRates, labels): Rates {
  if (!apiRates) return [];
  return apiRates.map((apiRate: APIRate) => {
    return (apiRate.Rates || []).reduce((acc: Rate, apiServiceRate: APIServiceRate) => {
      const rateToPush: ServiceRate = {
        name: apiServiceRate.Name,
        rate: apiServiceRate.Rate,
        type: getRateType(apiServiceRate.Unit),
        powerType: apiServiceRate.PowerType,
        priceType: apiServiceRate.PriceType,
      };
      if (isLowUserRate(apiServiceRate.UserType)) {
        acc.lowRates.push(rateToPush);
      } else if (isStandardUserRate(apiServiceRate.UserType)) {
        acc.standardRates.push(rateToPush);
      }
      return acc;
    }, {
      type: apiRate.Service,
      lowRates: [],
      standardRates: [],
      title: labels && labels[apiRate.Service][0],
      titleMobile: labels && labels[apiRate.Service][1],
    });
  });
}

/**
 * Returns true, if there is an error in electricity rates for specified usage type.
 * @param rates {Rates}
 * @param usageType {APIUsageType}
 * @return {boolean}
 */
export function hasElectricityRatesError(rates: Rates, usageType: APIUsageType): boolean {
  return Boolean(
    rates.find(rate => (
      rate.type === SERVICE_ID_ELECTRICITY &&
      (
        (usageType === USAGE_TYPE_STANDARD && !rate.standardRates.length) ||
        (usageType === USAGE_TYPE_LOW && !rate.lowRates.length)
      )
    ))
  );
}

/**
 * Returns true, if there is an error in gas rates.
 * @param rates {Rates}
 * @return {boolean}
 */
export function hasGasRatesError(rates: Rates): boolean {
  return rates && !rates.length;
}

/**
 * Returns service rates per usage type.
 * @param usageType {APIUsageType}
 * @param type
 * @param lowRates
 * @param standardRates
 * @return {Array<ServiceRate>}
 */
function getUsageTypeRates(usageType: APIUsageType, {type, lowRates, standardRates}: Rate): Array<ServiceRate> {
  return type === SERVICE_ID_ELECTRICITY
    ? usageType === USAGE_TYPE_LOW ? lowRates : standardRates
    : standardRates;
}

/**
 * Parses the rate (string, e.g 13c) and returns an instance of Decimal (number, value is in cents units).
 * @param rate {string}
 * @return {Decimal}
 */
function parseRate(rate: string) {
  const match = rate.match(/^(\$?)(\d+\.*\d*)(c?)$/);
  if (match) {
    // eslint-disable-next-line
    let [_, __, value, cents] = match;
    value = new Decimal(value);
    if (cents) {
      return value;
    } else {
      return value.times(100);
    }
  }
}

/**
 * Sums array of rates (string, e.g. 13c + $2.34) and returns a formatted value ($ or c).
 * @param rates {Array<string>}
 * @return {string}
 */
function sumRates(rates: Array<string>): string {
  const summed = rates.map(parseRate).reduce((acc, x) => acc.add(x), new Decimal(0));
  if (summed < 100) {
    return summed.toString() + 'c';
  } else {
    return '$' + summed.div(100).toString();
  }
}

/**
 * Returns rates which are summed (for rates overview).
 * @param usageType {APIUsageType}
 * @param rates {Rates}
 * @return {Array<{rateType: Rates.type, rates: Array<string>, usageType: APIUsageType}>}
 */
export function getSummedRates(usageType: APIUsageType, rates: Rates) {
  return rates
    .map((rate: Rate) => (
      getUsageTypeRates(usageType, rate)
        .reduce((acc, {type, rate}) => {
          if (!acc.rates[type]) {
            acc.rates[type] = [];
          }
          acc.rates[type] = [...acc.rates[type], rate];
          return acc;
        }, {usageType, rateType: rate.type, rates: {}})
    ))
    .map(({usageType, rateType, rates}) => ({
      usageType,
      rateType,
      rates: Object.keys(rates).reduce((acc, x) => {
        acc[x] = sumRates(rates[x]);
        return acc;
      }, {}),
    }));
}

export function getElectricityRates(apiRates: APIRates, labels): Rates {
  return getRates(apiRates, labels).filter(({ type }) => type === SERVICE_ID_ELECTRICITY);
}
