// @flow
import React, { useState, useEffect, Fragment } from 'react';
import axios from 'axios';
import Autosuggest from 'react-autosuggest';
import Paper from '@material-ui/core/Paper';
import debounce from 'lodash/debounce';
import classNames from 'classnames';

import { withStyles } from '@material-ui/core/styles';
import Input from '../Input';

import './Address.scss';
import customInput from './CustomInput';
import renderSuggestion from './Suggestion';
import AddressForm from './AddressForm';
import { type ApiProps } from '../types';
import { newBuild } from './constants';

const noAddress = "Can’t find your address?"

// Material UI styles
const styles = theme => ({
  root: {
    height: 60,
    marginBottom: '2rem',
    flexGrow: 1,
  },
  container: {
    position: 'relative',
  },
  suggestionsContainerOpen: {
    position: 'relative', // Ensures the the suggestion container renders without the need of scrolling in service modal and service cards
    zIndex: 1,
    marginTop: theme.spacing.unit,
    left: 0,
    right: 0,
  },
  suggestion: {
    display: 'block',
  },
  suggestionsList: {
    margin: 0,
    padding: 0,
    listStyleType: 'none',
  },
  divider: {
    height: theme.spacing.unit * 2,
  },
});

export type AddressDetails = {
  unit: string;
  streetNumber: string;
  streetName: string;
  suburb: string;
  city: string;
  postcode: string;
};
type Props = {
  disabled: boolean,
  suburbRequired: boolean,
  handleChange: () => void,
  handleMonikerChange?: () => void,
  value: string,
  placeholder: string,
  required: boolean,
  labelText: string,
  api: ApiProps,
  rightContent?: () => node,
  children?: () => node,
  isLoading: boolean,
  loaderCustomStyle?: Object,
  validateItself: boolean,
  onInputBlur: () => void,
  setManualAddress: (address: string, details: AddressDetails) => void,
  newBuildUrl?: string,
  setSuggestions: () => {},
};

const noAddressSuggestion = {
  id: 'no-address',
  name: noAddress,
  value: null,
};

async function fetchAddressSuggestions(address: string, api: ApiProps) {
  const url = api.baseUrl + '/address-cached/search?Query=' + address;
  const config = {
    headers: {
      'Content-Type': 'application/json',
      'x-api-key': api.key,
    }
  };
  const result = await axios(url, config);
  if (result.data && result.data.addresses) {
    const size = 5;
    const addresses = result.data.addresses.slice(0, size).map((ad, index) => ({ id: index, name: ad.pickList, value: ad }));
    addresses.push(noAddressSuggestion);
    return addresses;
  }
}

const debouncedFetchSuggestions = debounce((api, onSuccess, onError, value) => {
  if (value) {
    fetchAddressSuggestions(value, api)
      .then(onSuccess)
      .catch(onError);
  }
}, 250);

function composeAddressValue(address) {
  if (address) {
    return {
      pickList: address,
      partialAddress: address,
    };
  }
  return null;
}

const renderContent = (content, classes) => content
  ? React.cloneElement(content, {
    className: classNames(content.props.className, classes),
  })
  : null

function IntegrationAutosuggest(props: Props) {
  const { classes } = props;

  const [value, setValue] = useState(composeAddressValue(props.value));
  const [inputValue, setInputValue] = useState(props.value || '');

  // update input value state from props
  useEffect(() => {
    if (inputValue === noAddress) {
      if (props.value && props.value != (value && value.partialAddress)) {
        setInputValue(props.value || '');
      }
    } else {
      setInputValue(props.value || '');
    }
  }, [props.value]);

  const [suggestions, setSuggestions] = useState([]);
  const [addressForm, setAddressForm] = useState('');
  const [showNewBuild, setShowNewBuild] = useState(false);
  const [showForm, setShowForm] = useState(false);

  // Fire change event handler anytime the value changes
  useEffect(() => {
    props.handleChange(value && value.partialAddress || '');
    if (props.handleMonikerChange) {
      props.handleMonikerChange(value && value.moniker);
    }
  }, [value]);

  // Fire change event handler anytime address form value changes (valid address)
  useEffect(() => {
    if (inputValue === noAddress) {
      setValue(composeAddressValue(addressForm));
    }
  }, [addressForm, inputValue]);

  // Will be called every time you need to recalculate suggestions.
  const onSuggestionsFetchRequested = ({ value }) => {
    debouncedFetchSuggestions(
      props.api,
      suggestions => {
        if (suggestions.length === 1) {
          setShowForm(true);
        } else {
          setShowForm(false);
        }
        if (props.newBuildUrl && suggestions.length <= 1) {
          setSuggestions([]);
          setShowNewBuild(true);
          return;
        }
        setSuggestions(suggestions);
        props.setSuggestions(suggestions);
        setShowNewBuild(false);
      },
      () => {
        if (props.newBuildUrl) {
          setSuggestions([]);
          setShowNewBuild(true);
          return;
        }
        setShowNewBuild(false);
      },
      value,
    );
  };

  // When suggestion is clicked, Autosuggest needs to populate the input
  // based on the clicked suggestion. Teach Autosuggest how to calculate the
  // input value for every given suggestion.
  const getSuggestionValue = suggestion => suggestion.name;

  // Will be called every time suggestion is selected via mouse or keyboard.
  const onSuggestionSelected = (e, { suggestion }) => {
    setValue(suggestion.value);
  };

  const handleAddress = (address: string, details: AddressDetails) => {
    setAddressForm(address);
    props.setManualAddress(address, details);
    if (address) {
      setShowForm(false);
    }
  }

  const autosuggestProps = {
    suggestions: suggestions,
    onSuggestionsFetchRequested,
    //the suggestions must be kept because the user can click again in the field
    onSuggestionsClearRequested: () => { },
    getSuggestionValue,
    renderSuggestion,
    onSuggestionSelected,
  };

  const getAddressFromSuggestion = (address) => {
    const addressList = suggestions.filter(sug => sug.name === address);
    return addressList.length > 0 ? addressList[0] : null;
  }

  const onInputChange = (e, { newValue }) => {
    setInputValue(newValue);
    const address = getAddressFromSuggestion(newValue);
    address ? setValue(address.value) : setValue(null);
  };

  const onInputBlur = (e) => {
    if (inputValue !== noAddress) {
      const address = getAddressFromSuggestion(inputValue);
      address ? setValue(address.value) : props.handleError(true);
    }
    props.onInputBlur && props.onInputBlur(e);
  };

  if (props.disabled) {
    return <Input className={classes} labelText={props.labelText} value={inputValue} disabled />
  }

  const addressClassName = classNames(classes.root, {
    'address--autosuggest-container': true,
    'address--with-right-content': props.rightContent,
  });

  return (
    <Fragment>
      <div className={addressClassName}>
        <Autosuggest
          {...autosuggestProps}
          inputProps={{
            classes,
            value: inputValue,
            onChange: onInputChange,
            onBlur: onInputBlur,
            customstyle: props.loaderCustomStyle,
          }}
          theme={{
            container: classes.container,
            suggestionsContainerOpen: classes.suggestionsContainerOpen,
            suggestionsList: classes.suggestionsList,
            suggestion: classes.suggestion,
          }}
          renderInputComponent={(inputProps) => customInput(inputProps, props, inputValue)}
          renderSuggestionsContainer={options => (
            <Paper {...options.containerProps} square>
              {options.children}
            </Paper>
          )}
          shouldRenderSuggestions={inputVal => inputVal.length >= 2}
        />
        {renderContent(props.rightContent, {
          'address--content-wide': inputValue === noAddress || props.children
        })}
        {showNewBuild && showForm && <div className="address--new-build-text">{newBuild.preUrl}<a href={props.newBuildUrl}
          rel="noopener noreferrer"
          target="_blank">{newBuild.button}</a>{newBuild.postUrl}
        </div>}
      </div>
      {props.children}
      {(inputValue === noAddress || (showNewBuild && showForm)) && (
        <AddressForm
          handleChange={handleAddress}
          value={addressForm}
          handleError={props.handleError}
          validateItself={props.validateItself}
          suburbRequired={props.suburbRequired}
          hasEnterButton={props.newBuildUrl}
        />
      )}
      {renderContent(props.rightContent, {
        'address--content-narrow': inputValue === noAddress || props.children
      })}
    </Fragment>
  );
}

export default withStyles(styles)(IntegrationAutosuggest);
