// @flow
import React from 'react';
import HTML from "../../HTML";

type Props = {
  message: string,
};

const NoRates = ({ message }: Props) => (
  <div className="ratesContent_detailedRate">
    <div className="ratesError">
      <HTML html={message} />
    </div>
  </div>
);

export default NoRates;
