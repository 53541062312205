// @flow
import React from 'react';
import classNames from 'classnames';
import HTML from '../HTML';
import { type Messages } from './types';

type Props = {
  messages: Messages,
  className?: string,
  errorClassName?: string,
};

const Error = ({ messages, className, errorClassName }: Props) => (
  <div className={classNames('rates', className)}>
    <div className="rates_top-row">
      <div className={classNames('rates_error', errorClassName ? errorClassName : '')}>
        <HTML html={messages.error} />
      </div>
    </div>
  </div>
);

export default Error;
