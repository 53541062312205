// @flow
import React, { useEffect, useCallback, useReducer } from 'react'

import isEmpty from 'lodash/isEmpty';

import { AppElectricity, ArrowDown, Electricity } from '../../Assets/Icons';
import ExpandableCard from '../ExpandableCard';

import './ServiceCard.scss';
import ServiceCardField from './ServiceCardField';
import ServiceModal from './ServiceModal';

type Service = "ELEC" | "GAS" | "LPG" | "BB";
type Props = {
  serviceType: Service,
  canOpen: boolean,
  isOpen?: boolean,
  addValue: () => {},
  setlist: () => {},
  isInList: () => {},
  list: any[],
  icp: string,
  persistIcp: (icp: string) => void,
  onReceiveIcpDetails: (icp: string, details: any) => {},
  address: string,
  setAddress: () => {},
  setIsOpen: () => {},
  getIcpDetails: ({ icp: string }) => Promise<any>,
  getManualSearch: (address: string) => Promise<any>,
  setModalOpened: () => {},
  classes?: {
    card?: {},
    infoContainer?: {},
    checkbox?: {},
    icon?: {},
    title?: {},
  },
};

type MeterTypes = "Smart" | "Manual" | "PP" | "-";
type State = {
  isExpanded: boolean;
  showManualSearchForm: boolean;
  showManualICPForm: boolean;
  searchResults: { name: string; icp: string; icpStatus: number; }[];
  icp: null | string;
  icpDetails: null | {};
  meterType: MeterTypes;
  isSwitching: boolean;
};
const initialState: State = {
  // UI States
  isExpanded: false,
  showManualSearchForm: false,
  showManualICPForm: false,
  // Transient Data
  searchResults: [],
  // Display Data
  icp: null,
  icpDetails: null,
  meterType: '-',
  isSwitching: false,
};
type Actions =
  | { type: 'openCard' }
  | { type: 'closeCard' }
  | { type: 'openModal', payload: { modalType: 'AddressSearch' | 'ICP' }}
  | { type: 'closeModal' }
  | { type: 'storeICPDetails', payload: any }
  | { type: 'reset' }
  | { type: 'searchResults', payload: { address: string; icp: string; icpStatus: number; }[] }
  | { type: 'selectICP', payload: string }
  | { type: 'invalidICP' }
  | { type: 'setSwitching', payload: boolean }
  | { type: 'setMeterType', payload: MeterTypes }
  ;
const reducer = (state: State, action: Actions): State => {
  switch (action.type) {
    // Card
    case 'openCard':
      return {
        ...state,
        isExpanded: true,
      };
    case 'closeCard':
      return {
        ...state,
        isExpanded: false,
      };
    // Modals
    case 'openModal':
      return {
        ...state,
        showManualICPForm: action.payload.modalType === 'ICP',
        showManualSearchForm: action.payload.modalType === 'AddressSearch',
      };
    case 'closeModal':
      return {
        ...state,
        showManualSearchForm: false,
        showManualICPForm: false,
      };
    // Search Results
    case 'searchResults':
      return {
        ...state,
        showManualSearchForm: false,
        searchResults: action.payload.map(({ address, ...rest }) => ({ name: address, ...rest })),
        icp: null,
        icpDetails: null,
      };
    case 'selectICP':
      return {
        ...state,
        showManualSearchForm: false,
        showManualICPForm: false,
        searchResults: [],
        icp: action.payload,
      }
    case 'invalidICP':
      return {
        ...state,
        icp: null,
        icpDetails: null,
      };
    // ICP Data
    case 'storeICPDetails':
      return {
        ...state,
        icpDetails: action.payload
      };
    // Switches
    case 'setSwitching':
      return { ...state, isSwitching: action.payload };
    case 'setMeterType':
      return { ...state, meterType: action.payload };
    // Reset
    case 'reset':
      return initialState;
    default:
      throw new Error(`ServiceCard Reducer, no action of type "${action.type}" found.`);
  }
}

const ServiceCard = (props: Props) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  // Utils:
  const getMeterType = (code) => {
    if (!code) {
      return "-"
    }
    return code;
  };

  useEffect(() => {
    if (props.icp) {
      dispatch({ type: "selectICP", payload: props.icp })
    } else {
      dispatch({ type: "selectICP", payload: null })
    }
  }, [props.icp])

  useEffect(() => {
    dispatch({ type: props.isOpen ? 'openCard' : 'closeCard' })
  }, [props.isOpen]);

  // API Calls
  // Fetch ICP Details
  useEffect(() => {
    if (state.icp) {
      props.getIcpDetails({ "icp": state.icp })
        .then(resp => {
          if (!isEmpty(resp.data.icp)) {
            dispatch({
              type: 'storeICPDetails',
              payload: resp.data.icp,
            });
            props.onReceiveIcpDetails(state.icp, resp.data.icp);
            props.persistIcp(state.icp);
          } else {
            dispatch({ type: 'invalidICP' });
          }
        })
    } else {
      dispatch({
        type: 'storeICPDetails',
        payload: null,
      });
    }
  }, [state.icp]);

  // Processors:
  // Meter Type & Switching Flag
  useEffect(() => {
    if (state.icpDetails && !isEmpty(state.icpDetails)) {
      dispatch({ type: "setSwitching", payload: state.icpDetails?.switchInProgress === 'true' })
      dispatch({ type: 'setMeterType', payload: getMeterType(state.icpDetails?.meterType) })
    } else {
      dispatch({ type: "setSwitching", payload: false })
      dispatch({ type: 'setMeterType', payload: '-' })
    }
  }, [state.icpDetails])

  const handleModalOpen = useCallback((modalType) => () => {
    dispatch({ type: 'openModal', payload: { modalType }});
    props.setModalOpened(modalType);
  }, [dispatch, props.setModalOpened]);

  const handleManualAddressSubmission = (address) => {
    props.getManualSearch(address).then((response) => {
      dispatch({ type: 'searchResults', payload: response.data })
    });
  }

  // Helper for elec service card content
  const buildElectricityServiceContent = () => {
    return (
      <div className="serviceCard_content">
        <ServiceCardField
          fieldType="button"
          value={state.icp ?? "ICP not found"}
          title="Electricity ICP"
          buttonTitle="Copy"
          hasError={!state.icp}
        />
        <ServiceCardField
          fieldType="address"
          title="Registry Address"
          setShowAddressModal={handleModalOpen('AddressSearch')}
          setShowIcpModal={handleModalOpen('ICP')}
          value={state.icp ? state.icpDetails?.fullAddress ?? props.address ?? '' : ''}
          addressList={state.searchResults}
          onIcpSelect={(icp) => dispatch({ type: 'selectICP', payload: icp })}
        />
        <ServiceCardField
          fieldType="standard"
          value={state.icpDetails?.status || "-"}
          title="Status"
          isValid
          showSuccessIcon={state.icpDetails?.status}
        />
        <ServiceCardField
          fieldType="standard"
          value={state.isSwitching}
          title="Switch in progress"
          hasError={state.isSwitching}
        />
        <ServiceCardField
          fieldType="standard"
          value={state.icpDetails?.serial || "-"}
          title="Serial number"
        />
        <ServiceCardField
          fieldType="standard"
          value={state.meterType}
          title="Meter type"
        />
        <ServiceCardField
          fieldType="standard"
          value={state.icpDetails?.trader || "-"}
          title="Current retailer"
        />
			</div>

    )
  }

  const handleCheckboxClick = (e) => {
    e.stopPropagation();
    props.addValue(props.serviceType, props.list, props.setlist);
  }

  // Helper for elec service card
  const buildElectricityServiceCard = () => {
    return (
      <>
        <ExpandableCard
          headerContent={{
            cardTitle: "Electricity",
            cardIcon: <ArrowDown />,
            cardIsSelected: true,
            disabled: false,
            mainIcon: <Electricity />,
            withCheckbox: true,
          }}
          classes={props.classes}
          isOpen={state.isExpanded}
          name={"ELECTRICITY"}
          disabled={!props.canOpen}
          content={buildElectricityServiceContent()}
          onCheckboxChange={(e) => handleCheckboxClick(e)}
          checked={props.isInList(props.serviceType, props.list)}
          handleClick={() => {dispatch({ type: state.isExpanded ? 'closeCard' : 'openCard' }); props.setIsOpen(!state.isExpanded)}}
        />
        <ServiceModal
          modalType="address"
          isActive={state.showManualSearchForm}
          handlePrimaryClick={() => dispatch({ type: 'closeModal' })}
          handleManualAddressSubmission={handleManualAddressSubmission}
          serviceType="electricity"
        />
        <ServiceModal
          modalType="icp"
          isActive={state.showManualICPForm}
          handlePrimaryClick={() => dispatch({ type: 'closeModal' })}
          handleIcpChange={(icp) => dispatch({ type: 'selectICP', payload: icp })}
          serviceType="electricity"
        />
      </>
    );
  }

  // Render card based on type
  switch (props.serviceType) {
    case 'ELEC':
      return buildElectricityServiceCard();
    default:
      return null
  }


};

export default ServiceCard;
