// @flow
import React, { useState } from 'react';

import Input from '../Input';
import Modal from '../Modal';
import { ServiceModalConstants } from '../../Config/Constants'
import Address from '../Address'

import './ServiceCard.scss';

type ServiceModalType = "address" | "icp";

type ServiceType = "broadband" | "piped gas";

type ApiType = {
  baseUrl: string,
  key: string,
}

type Props = {
  modalType: ServiceModalType, 
  isActive: boolean,
  serviceType: ServiceType,
  handlePrimaryClick: () => {},
  handleIcpChange: (param: string) => {},
  handleManualAddressSubmission: (param: Object) => {},
  onAddressSearch: () => {},
  searchResults: any,
  handleSecondaryClick: (param: string) => {},
  api: ApiType,
  name: string,
  errorMessage: string,
  label: string,
  handleManualChange: (param1: any, param2: any) => void,
};

const ServiceModal = (props: Props) => {
  
  // Address Modal
  const [streetNumber, setStreetNumber] = useState('');
  const [streetName, setStreetName] = useState('');
  const [suburbOrTown, setSuburbOrTown] = useState('');
  const [region, setRegion] = useState();
  const [validateItself, setValidateItself] = useState(false);
  const [address, setAddress] = useState("");
  const [addressHasError, setAddressHasError] = useState(false);

  // ICP Modal
  const [icp, setIcp] = useState('');

  // Address modal builder based on service type
  const buildAddressModal = () => {
    if (props.serviceType === "broadband") {
      return (
        <Modal
          showClose
          clearSessionStyle
          isActive={props.isActive}
          buttonTextPrimary={ServiceModalConstants.buttonTextPrimary}
          handlePrimaryClick={props.handlePrimaryClick}
          buttonTextSecondary={ServiceModalConstants.buttonTextSecondary}
          handleSecondaryClick={() =>
            props.handleSecondaryClick(address)
          }
          content={buildAddressModalContent()}
          contentStyle={{
            marginLeft: "20px",
            marginRight: "20px",
            marginTop: "60px",
            marginBottom: "60px",
          }}
          handleValidationClick={() => setValidateItself(true)}
          buttonDisabled={!address}
        />
      );
    } else {
      return (
        <Modal
          showClose
          clearSessionStyle
          isActive={props.isActive}
          buttonTextPrimary={ServiceModalConstants.buttonTextPrimary}
          handlePrimaryClick={props.handlePrimaryClick}
          buttonTextSecondary={ServiceModalConstants.buttonTextSecondary}
          handleSecondaryClick={() => {props.handleManualAddressSubmission({"streetNumber": streetNumber, "streetName": streetName, "suburbOrTown": suburbOrTown, "region": region})}}
          content={buildManualAddressModalContent()}
          contentStyle={{
            marginLeft: "20px",
            marginRight: "20px",
            marginTop: "60px",
            marginBottom: "60px",
            textAlign: "center"
          }}
        />
      );
    }
  }

  const buildAddressModalContent = () => {
    return (
      <>
        <strong>Manually enter details for {props.serviceType}</strong>
        <Address
          api={props.api}
          labelText={props.label}
          handleChange={setAddress}
          value={address}
          handleError={setAddressHasError}
          setManualAddress={props.handleManualChange}
        />
        {addressHasError && <p className="serviceCard_errorContainer">{ServiceModalConstants.errorMessage}</p>}
      </>
    );
  }

  const buildManualAddressModalContent = () => {
    return(
      <>
        <strong>Manually enter details for {props.serviceType}</strong>
        <Input 
          labelText={ServiceModalConstants.StreetNumber}
          value={streetNumber}
          handleChange={setStreetNumber}
        />
        <Input 
          labelText={ServiceModalConstants.StreetName}
          value={streetName}
          handleChange={setStreetName}
        />
        <Input 
          labelText={ServiceModalConstants.SuburbTown}
          value={suburbOrTown}
          handleChange={setSuburbOrTown}
        />
        <Input 
          labelText={ServiceModalConstants.Region}
          value={region}
          handleChange={setRegion}
        />
      </>
    );
  }

  // Icp Modal builder
  const buildIcpModal = () => {
    return (
      <Modal
				showClose
				clearSessionStyle
				isActive={props.isActive}
				buttonTextPrimary={ServiceModalConstants.buttonTextPrimary}
				handlePrimaryClick={props.handlePrimaryClick}
				buttonTextSecondary={ServiceModalConstants.buttonTextSecondary}
				handleSecondaryClick={() => {props.handleIcpChange(icp)}}
				content={buildIcpModalContent()}
				contentStyle={{
					marginLeft: "20px",
					marginRight: "20px",
					marginTop: "60px",
					marginBottom: "60px",
					textAlign: "center"
				}}
			/>
    );
  }

  const buildIcpModalContent = () => {
    return(
      <>
        <strong>Manually enter an {props.serviceType} ICP</strong>
        <Input 
          labelText={`${props.serviceType} ICP`}
          handleChange={setIcp}
          value={icp}
        />
      </>
    );
  }


  // Build correct field type
  switch (props.modalType) {
    case "address":
      return buildAddressModal();
    case "icp":
      return buildIcpModal();
    default:
      return null;
  }
};

export default ServiceModal;