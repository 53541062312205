// @flow
import React from 'react';
import { compose, branch, renderNothing, withProps } from 'recompose';
import classNames from 'classnames';
import Icon from '../../Icon';
import { AppElectricity } from '../../../Assets/Icons';
import type { APIUsageType, Messages, Rate as RateType } from "../types";
import {
  USAGE_TYPE_LOW,
  USAGE_TYPE_STANDARD,
  SERVICE_ID_ELECTRICITY,
  SERVICE_ID_PIPED_GAS,
} from '../constants';

import Tooltip from '../../Tooltip';

type Props = {
  usageType: APIUsageType,
  rate: RateType,
  messages: Messages,
  areRatesToggleable: boolean,
  isNarrow?: boolean,
  isIcon?: boolean,
  isSummary?: boolean,
  managedContent?: Object,
  onToggleUsageType?: () => void,
};
const usageTypeLabel = {
  [USAGE_TYPE_LOW]: 'Low',
  [USAGE_TYPE_STANDARD]: 'Standard',
};
const DetailedRate = ({ rate, usageType, messages, areRatesToggleable, onToggleUsageType, currentRates, isNarrow, isSummary, isIcon, managedContent }: Props) => (
  <div className={classNames("ratesContent_detailedRate", isNarrow ? "ratesContent_detailedRate--narrow" : "", isSummary ? "ratesContent_detailedRate--summary" : "")}>
    <div className="ratesContent_detailedRate_heading">
      <div className="ratesContent_detailedRate_heading_title">
        {rate.type === SERVICE_ID_ELECTRICITY && !isSummary && (
          <div className="ratesContent_detailedRate_label">
            {isIcon && (
              <Icon
                icon={<AppElectricity />}
                className="ratesContent_detailedRate_label_icon"
              />
            )}
            <RateLabel
              label={messages.electricityLabel}
              userRatesLabel={usageType === USAGE_TYPE_LOW ? messages.lowUserRates : messages.standardUserRates}
            />
            <Tooltip className="ratesContent_detailedRate_tooltip">{managedContent?.RatesTTDescription.value}</Tooltip>
          </div>
        )}
        {rate.type === SERVICE_ID_PIPED_GAS && (
          <RateLabel
            label={messages.gasLabel}
            userRatesLabel={messages.standardUserRates}
          />
        )}
      </div>
    </div>
    <div className="ratesContent_detailedRate_data">
      <div className="ratesContent_detailedRate_data_rates">
        {currentRates.map((r, i) => (
          <Rate
            key={'DR' + i}
            title={r.name}
            price={r.rate}
            value={r.type}
            messages={messages}
            isNarrow={isNarrow}
            isSummary={isSummary}
          />
        ))}
      </div>
      {areRatesToggleable && (
        <div>
          <span className="ratesContent_detailedRate_link" onClick={onToggleUsageType}>{getSwitchRatesLabel(usageType, messages.usageTypeSwitch)}</span>
        </div>
      )}
    </div>
  </div>
);

const getSwitchRatesLabel = (usageType, message) => {
  const switchedUsageType = usageType === USAGE_TYPE_STANDARD ? USAGE_TYPE_LOW : USAGE_TYPE_STANDARD;
  const utLabel = usageTypeLabel[switchedUsageType];
  if (!message || !utLabel) return '';
  return message.replace('%usageType%', utLabel.toLowerCase())
}

const getSwitchText = (usageType, message) => {
  const currentUsageType = usageType === USAGE_TYPE_STANDARD ? USAGE_TYPE_STANDARD : USAGE_TYPE_LOW;
  const currentUserLabel = usageTypeLabel[currentUsageType];
  if (message.indexOf('currentUsageType') > -1) {
    return message.replace('%currentUsageType%', currentUserLabel.toLowerCase())
  }
}

const RateLabel = ({ label, userRatesLabel }) => (
  <>
    <strong style={{marginRight: 5}}>{label}</strong>
    {'(' + userRatesLabel + ')'}
  </>
);

const Rate = props => (
  <div className={classNames("ratesContent_detailedRate_data_rates_rate",
    props.isNarrow ? "ratesContent_detailedRate_data_rates_narrow-rate" : "",
    props.isSummary ? "ratesContent_detailedRate_data_rates_rate_summary" : "")
  }>
    <span className={classNames(props.isSummary ?
      "ratesContent_detailedRate_data_rates_rate_title_summary" : "ratesContent_detailedRate_data_rates_rate_title")}>
      {props.title}
    </span>
    <span className="ratesContent_detailedRate_data_rates_rate_value">
      <strong>{props.price}</strong>
      {props.value === 'day' ? '/day' : '/kWh'}
    </span>
  </div>
);

export default compose(
  branch(
    // render only for electricity an d piped gas and only if rates are set
    ({ rate, usageType }) => (
      !rate ||
      ![SERVICE_ID_ELECTRICITY, SERVICE_ID_PIPED_GAS].includes(rate.type) ||
      (rate.type === SERVICE_ID_ELECTRICITY && usageType === USAGE_TYPE_LOW
        ? !rate.lowRates || !rate.lowRates.length
        : !rate.standardRates || !rate.standardRates.length
      )
    ),
    renderNothing
  ),
  withProps(({ usageType, rate }) => ({
    currentRates: usageType === USAGE_TYPE_LOW && [SERVICE_ID_ELECTRICITY, SERVICE_ID_PIPED_GAS].includes(rate.type)
      ? rate.lowRates
      : rate.standardRates
  })),
)(DetailedRate);
