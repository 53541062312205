// @flow
import React, { useState, useEffect } from 'react';
import classNames from 'classnames';

import './Checkbox.scss';
import Icon from '../Icon';
import {Check, Help, Uncheck, UncheckError} from '../../Assets/Icons';

type Props = {
  name: string,
  checked: boolean,
  label: React.Node,
  subLabel: React.Node,
  onChange: () => void,
  hasHelpIcon: () => boolean,
  iconClicked: () => void,
  className?: string,
  required: boolean,
  validateItself: boolean,
};

const Checkbox = (props: Props) => {

  const [icon, setIcon] = useState(<Uncheck />);

  useEffect(() => {
    setIcon(props.checked ? <Check /> : props.validateItself && props.required ? <UncheckError/> : <Uncheck />)
  }, [props.checked, props.validateItself, props.required]);

  const helpIconClick = (e) => {
    e.stopPropagation();
    props.iconClicked && props.iconClicked();
  };
  
  const onKeyDown = (e) => {
    if(e.key === ' '){ 
      props.onChange();
      e.preventDefault();
    }
  }

  return (
    <div id={props.name ? props.name : null} name={props.name ? props.name : null} className={classNames('checkbox', props.className)} onClick={props.onChange}>
      <Icon className="checkbox_box" icon={icon} tabindex="0" onKeyDown={onKeyDown}/>
      <div className="checkbox_container">
        <div name={props.name ? props.name + "Label" : null} className="checkbox_label">
          {props.label}
        </div>
        <div name={props.name ? props.name + "SubLabel" : null} className="checkbox_sublabel">
          {props.subLabel}
        </div>
      </div>
      { props.hasHelpIcon && <Icon name="helpIcon" onClick={helpIconClick} className="checkbox_box checkbox_icon" icon={<Help/>} /> }
    </div>
  );
};

export default Checkbox;
