// @flow
import React, { Fragment } from 'react'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'

import { title, findAddress } from './constants'
import './Icp.scss'

export type PossibleAddress = {
  name: string,
  icp?: string,
  value?: string,
};

type Props = {
  possibleAddresses: Array<PossibleAddress>,
  onClick: () => void,
  listOnly?: boolean,
};

const IcpSelectList = (props: Props) => props.possibleAddresses.length > 1 ? (
  <Fragment>
    {!props.listOnly && <h3>{title}</h3>}
    <List>
      {props.possibleAddresses.map((address, index) => (
        <ListItem button key={index} onClick={() => {
          props.listOnly ? props.onClick(address.icp ?? address.value) : props.onClick(address)
        }}>
          {`${address.name ? address.name : address.address}${address.icp ? `- ICP: ${address.icp}` : ''}`}
        </ListItem>
      ))}
    </List>
    {!props.listOnly &&
    <div className='icp'>
      <span>{findAddress.text}</span>
      <span className="icp_action" onClick={() => props.onClick({})}>
          {findAddress.action}
        </span>
    </div>
    }
  </Fragment>
) : null

export default IcpSelectList
