// @flow
import React from 'react';
import classNames from 'classnames';

import './Tooltip.scss';

type Props = {
  children?: node,
  className?: string,
};

const Tooltip = (props: Props) => (
  <div className={classNames(['tooltip', props.className])}>
    <div className="tooltip_info">i</div>
    <div className="tooltip_content">
      {props.children}
    </div>
  </div>
);

export default Tooltip;