// @flow
import React, { useState, useEffect } from 'react';
import _isEmpty from 'lodash/isEmpty';

import IcpSelectList from './IcpSelectList';

type Props = {
  addressDetails: any,
  request: () => Promise<any>,
  onSuccess?: () => void,
  onSelect: () => void,
  onSelectLater: () => void,
};

const mapAddress = ({ IcpAddress, IcpNumber }) => ({ icp: IcpNumber, name: IcpAddress });

const IcpSelect = (props: Props) => {
  const [possibleAddresses, setPossibleAddresses] = useState([]);

  const handleResponse = response => {
    const addresses = response.data.PossibleAddresses.map(mapAddress);

    if (addresses.length === 1) {
      props.onSelect(addresses[0].icp);
    }

    setPossibleAddresses(addresses);

    return addresses;
  };

  const handleSelect = ({ icp }) => {
    if (icp) {
      props.onSelect(icp);
    } else {
      props.onSelectLater();
    }
    setPossibleAddresses([]);
  };
  
  useEffect(() => {
    if (!_isEmpty(props.addressDetails)) {
      props.request(props.addressDetails)
        .then(handleResponse)
        .then(addresses => props.onSuccess && props.onSuccess(addresses));
    }
  }, [props.addressDetails]);

  return <IcpSelectList possibleAddresses={possibleAddresses} onClick={handleSelect} />;
};

export default IcpSelect;