// @flow

import React, { useState, useEffect, useRef } from "react";

import Icon from '../Icon';
import { ArrowDown } from '../../Assets/Icons';

import "./Dropdown.scss";

type Props = {
    labelText: String,
    selectedItem: String,
    selectedItemIndex: Number,
    handleSelectedItem: () => {},
    handleSelectedItemIndex: () => {},
    items: Array,
    placeholder: string,
    handleError: () => void,
    hasError: boolean,
    errorMessage: string,
    handleBlur: () => void,
    required: boolean,
    validateItself: boolean,
    isServiceCard: boolean
  };

const Dropdown = (props: Props) => {
  const [showDropdown, setShowDropdown] = useState(false);
  const [touched, setTouched] = useState(false);
  const dropDownContainer = useRef();

  //trigger auto validation
  useEffect(() => {
    if (props.validateItself && !props.hasError && props.required && !props.value){
      setTouched(true);
      props.handleError(true);
    }
  }, [props.validateItself, props.value, props.handleError, props.required, props.hasError]);

  useEffect(() => {
    if (showDropdown) {
      document.addEventListener("mousedown", handleClick);
      // return function to be called when unmounted
      return () => {
        document.removeEventListener("mousedown", handleClick);
      };
    }
  }, [showDropdown])

  useEffect(() => {
    touched && !props.selectedItem && props.handleError ? props.handleError(true) : null;
  }, [touched]);

  const handleClick = event => {
    // outside click
    if (showDropdown && dropDownContainer.current !== undefined && 
        !dropDownContainer.current.contains(event.target)) {
      setShowDropdown(false);
    }
  };

  const updateStateIfDefaultIsFirstItem = () => {
    props.handleSelectedItem(props.items[0]);
    props.handleSelectedItemIndex(0);
  }

  useEffect(() => {
    props.selectedItem ? props.selectedItem :
      props.items.length > 1 && props.placeholder ? 
        props.placeholder : updateStateIfDefaultIsFirstItem();
  }, [props.selectedItem, props.items, props.placeholder]);

  return (
    <React.Fragment>
      {/* eslint-disable-next-line */}
      <div 
        className="dropdown"
        type="button" 
        ref={dropDownContainer} 
        onClick={() => {
          props.items.length > 1 ? setShowDropdown(!showDropdown) : "";
        }}
        onBlur={() => {
          setTouched(true);
          props.handleBlur ? props.handleBlur() : null;
        }}
        tabIndex={0}
        role="button"
      >
        {!props.hasError && (
          <span className={`dropdown_label ${showDropdown ? "dropdown_label--selected" : ""}`}>
          {props.labelText}
        </span>
        )}
        {props.hasError && (
          <span className={`dropdown_label dropdown_label_error ${showDropdown ? "dropdown_label--selected" : ""}`}>
            {props.errorMessage}
          </span>
        )}
        <div className="dropdown_selected">
          <div className="dropdown_labelWrapper">
            <div className="dropdown_selectedItem">{props.selectedItem || props.placeholder}</div>
            <div>{props.items.length > 1 && <Icon icon={<ArrowDown/>} className="dropdown_icon" />}</div>
          </div>
        {showDropdown && (
          <ul className={props.isServiceCard ? "dropdown_service_card_list" : "dropdown_list"}>
            {props.items.map((item, index) => (
              <li
                className="dropdown_listItem"
                key={item+index}
              >
                <div
                  className="dropdown_option"
                  onClick={(e) => {
                    props.handleSelectedItem(item)
                    props.handleSelectedItemIndex(index)
                  }}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      props.handleSelectedItem(item)
                      props.handleSelectedItemIndex(index)
                    }
                  }}
                  tabIndex={0}
                  role="option"
                  aria-selected={props.selectedItem === item}
                >
                {item}
                </div>
              </li>
            ))}
          </ul>
        )}
        </div>
      </div>
    </React.Fragment>
  );
};

Dropdown.defaultProps = {
  labelText: "What is your message about?",
  value: "Billing",
  defaultValue: "Billing",
  handleSelectedItem: () => {},
  handleSelectedItemIndex: () => {},
  placeholder: "",
}

export default Dropdown;
