// @flow
import React, { useState, useEffect } from 'react';
import { useSpring, animated, config } from 'react-spring';
import classNames from 'classnames';
import _debounce from 'lodash/debounce';

import DatePicker from 'react-datepicker';
import Icon from '../Icon';
import { Calendar, Error } from '../../Assets/Icons';
import DateUtil from '../../Utils/DateUtil';
import { validateDate, validateFirstDateBeforeSecond } from '../../Utils/ValidationUtil';
import 'react-datepicker/dist/react-datepicker.css';
import './DatePicker.scss';


type Props = {
  disabled: boolean,
  handleChange: () => void,
  name: string,
  placeholder: string,
  required: boolean,
  labelText: string,
  iconLast: boolean,
  isSmall: boolean,
  validDates: [],
  numberValidDays: number,
  onlyWeekdays: boolean,
  maxDate: Date,
  minDate: Date,
  handleError: () => {},
  hasError: Boolean,
  errorMessage: string,
  minErrorMessage: string,
  maxErrorMessage: string,
  value: Date,
  validateItself: boolean,
  numberYearOptions: number,
};

const DataPicker = (props: Props) => {
  const MobileWidth = 640;
  const [ placeholderState, setPlaceholderState ] = useState('');
  const [ isActive, setActive ] = useState(false);
  const [ touched, setTouched ] = useState(false);
  const [ withPortal, setWithPortal ] = useState(false);

  //trigger auto validation
  useEffect(() => {
    if (props.validateItself && !props.hasError && props.required && !props.value){
      setTouched(true);
      props.handleError(true);
    }
  }, [props.validateItself, props.value, props.handleError, props.required, props.hasError]);

  useEffect(() => {
    setActive(props.value ? true : isActive);
  }, [props.value]);

  const getResizeData = () => {
    setWithPortal(window.innerWidth < MobileWidth);
  };

  const debounceGetResizeData = _debounce(getResizeData, 150);

  useEffect(() => {
    window.addEventListener('resize', debounceGetResizeData);
    return () => {
      window.removeEventListener('resize', debounceGetResizeData);
    };
  })

  useEffect(() => {
    getResizeData();
  }, [])

  const [animateLabel, setLabel] = useSpring(() => ({ bottom: '0px', left: props.iconLast ? '0' : '36px', fontSize: '18px', config: config.stiff }));
  const [animateInput, setInput] = useSpring(() => ({ opacity : 0, config: config.stiff, width: props.isSmall ? '150px' : '100%' }));

  setLabel({
    opacity: isActive ? 0.6 : 1,
    bottom: isActive ? '28px' : '0px',
    fontSize: isActive ? '14px' : '18px',
  })
  setInput({
    opacity: isActive ? 1 : 0,
  })

  const dateOnChangeHandler = (value) => {
    props.handleError ? validateDate(props.minDate, props.maxDate, value, props.handleError) : null;
    props.handleChange(value);
  }

  const replaceInputHandler = (event) => {
    const cursorPosition = event.target.selectionStart;
    const initiallength = event.target.value.length;
    event.target.value = event.target.value
      .replace(/[^\d-]/g, "")
      .replace(/[\/]?/g, "")
      .replace(/(^\d{2})/g, "$1/")
      .replace(/(^\d{2}\/\d{2})/g, "$1/")
      .replace(/[\/]$/g, "");
    const finallength = event.target.value.length;
    const newCursorPosition = cursorPosition + finallength - initiallength;
    event.target.setSelectionRange(newCursorPosition, newCursorPosition);
  }

  const dateOnFocusHandler = (event) => {
    event.target.readOnly = window.innerWidth < MobileWidth;
    setPlaceholderState(props.placeholder);
    setActive(true);
  }

  const dateOnBlurHandler = () => {
    setTouched(true);
    setPlaceholderState('');
    setActive(props.value != null);
  }

  const getErrorMessage = () => {
    return validateFirstDateBeforeSecond(props.value, props.minDate) ? props.minErrorMessage : props.maxErrorMessage;
  }

  const customInput = (
    <animated.input
      id={props.name}
      name={props.name}
      type="text"
      htmlFor={props.name}
      style={animateInput}
      maxLength={10}
    />
  );

  const dates = props.validDates ? props.validDates : [];

  if(props.numberValidDays) {
    for(let days = 0; days < props.numberValidDays; days++){
      dates.push(DateUtil.addDays(new Date(), days));
    }
  }

  const icon = (
    <span className="dateContainer_calendar">
      <Icon icon={<Calendar />} className="dateContainer_calendar_icon" />
    </span>
  );

  const classes = classNames({
    'dateContainer': true,
    'dateContainer--spacearound': props.iconLast,
  });

  return (
    <div className={classes} disabled={props.disabled}>
        {/* eslint-disable-next-line*/}
      {props.labelText && !props.hasError && (
      <animated.label
        style={animateLabel}
        id={props.name}
        name={props.name}
        htmlFor={props.name}
        className="dateContainer_label"
      >
        {props.labelText}
      </animated.label>
      )}
      {/* eslint-disable jsx-a11y/label-has-for */}
      {props.hasError && (
        <label
          style={{ color: 'red',
            opacity: 0.6,
            bottom: '28px',
            fontSize: '14px' }}
          id={props.name}
          name={props.name}
          htmlFor={props.name}
          className="dateContainer_label"
        >
          {props.value && (props.minErrorMessage || props.maxErrorMessage) 
            ? getErrorMessage()
            : props.errorMessage
          }
        </label>
      )}
      {props.iconLast ? null : icon}
      <DatePicker 
        className='dateContainer_input'
        dateFormat="dd/MM/yyyy"
        selected={props.value}
        onChange={dateOnChangeHandler}
        placeholderText={placeholderState}
        required={props.required}
        disabled={props.disabled}
        onFocus={dateOnFocusHandler}
        onBlur={dateOnBlurHandler}
        customInput={customInput}
        includeDates={props.numberValidDays || props.validDates ? dates : null}
        filterDate={props.onlyWeekdays ? DateUtil.isWeekday : null}
        maxDate={props.maxDate}
        minDate={props.minDate}
        withPortal={withPortal}
        onChangeRaw={replaceInputHandler}
        disabledKeyboardNavigation
        showYearDropdown
        dateFormatCalendar="MMMM"
        scrollableYearDropdown
        yearDropdownItemNumber={props.numberYearOptions ? props.numberYearOptions : 3}
      />
      {!props.hasError && props.iconLast ? icon : null}
      {props.hasError && (<Icon icon={<Error />} className="dateContainer_icon--error" /> )}
    </div>
  );
};

export default DataPicker;
