// @flow
import React from 'react'
import HTML from '../../HTML';

import './styles.scss';

import type { Rate, Rates, Messages } from '../types';
import { SERVICE_ID_ELECTRICITY } from '../constants'

import NoRates from './NoRates';
import DetailedRate from './DetailedRate';
import type { APIUsageType } from "../types";

type Props = {
  elecUsageType: APIUsageType,
  gasUsageType: APIUsageType,
  messages: Messages,
  rates: Rates,
  gasRates: Rates,
  hasElectricityRatesError: boolean,
  hasGasRatesError: boolean,
  isBachPlan: boolean,
  isNarrow?: boolean,
  isSummary?: boolean,
  isIcon?: boolean,
  gasOnly?: boolean,
  managedContent?: Object,
  onToggleUsageType: () => void,
};
const RatesContent = (props: Props) => {
  return (
    <div className="ratesContent">
      <div className="ratesContent_rates">
        {props.hasElectricityRatesError && (
          <NoRates message={props.messages.noElectricityRates} />
        )}

        {props.rates && !props.gasOnly && props.rates.map((rate: Rate, key: number) => (
          <DetailedRate
            key={key}
            messages={props.messages}
            rate={rate}
            usageType={props.elecUsageType}
            areRatesToggleable={rate.type === SERVICE_ID_ELECTRICITY && !props.isBachPlan}
            onToggleUsageType={props.onToggleUsageType}
            isNarrow={props.isNarrow}
            isIcon={props.isIcon}
            isSummary={props.isSummary}
            managedContent={props.managedContent}
          />
        ))}
        {props.gasRates && props.gasRates.map((rate: Rate, index: number) => (
          <DetailedRate
            key={index}
            messages={props.messages}
            rate={rate}
            usageType={props.gasUsageType}
            areRatesToggleable={false} /* TODO: Fix after CSRT-559 is done */
            onToggleUsageType={props.onToggleUsageType}
            isNarrow={props.isNarrow}
            isIcon={props.isIcon}
            isSummary={props.isSummary}
            managedContent={props.managedContent}
          />
        ))}
        {props.hasGasRatesError && (
          <NoRates message={props.messages.noGasRates} />
        )}
      </div>

      <div className="ratesContent_legal">
        <HTML html={props.managedContent?.Disclaimer?.value} />
      </div>
    </div>
  );
};

export default RatesContent;
