// @flow
import React from 'react';
import axios from 'axios';
import VerifiedInput, { type Props as VerifiedInputProps } from '../VerifiedInput';
import { type ApiProps } from '../types';

type Props = {
  ...VerifiedInputProps,
  api: ApiProps,
};

const length = 19;

/**
 * Formats AA Smartfuel number.
 * Format of the number is: "0000 0000 0000 0000".
 * @param value {string}
 * @return {string}
 */
function formatAASmartfuel (value: string): string {
  return value
    .replace(/[^\d\s]/g, "")
    .replace(/[\s]?/g, "")
    .replace(/(^\d{4})/g, "$1 ")
    .replace(/(^\d{4}\s\d{4})/g, "$1 ")
    .replace(/(^\d{4}\s\d{4}\s\d{4})/g, "$1 ")
    .replace(/[\s]$/g, "");
}

/**
 * Verifies the AA Smartfuel number using API.
 * @param aaSmartFuel
 * @param api
 * @return {Promise<boolean>}
 */
async function verifyAaSmartFuel (aaSmartFuel: string, { api }: Props): boolean {
  const url = api.baseUrl + '/smart-fuel/checkbalance/';
  const data = {
    "partner": "CTE",
    "terminal": "CTE",
    "cardNumber": aaSmartFuel.split(' ').join(''),
  };
  const config = {
    headers: {
      'Content-Type': 'application/json',
      'x-api-key': api.key,
    },
    mode: 'cors',
  };

  const result = await axios.post(url, data, config);
  return Boolean(result.data && result.data.loyaltyresponse && result.data.loyaltyresponse.currentbalanceresult);
}

/**
 * Returns true, if value is ready to be verified.
 * @param value
 * @returns {string|boolean}
 */
function isToBeVerified(value: string): boolean {
  return value && value.length === length;
}

function AaSmartFuelInput(props: Props) {
  return (
    <VerifiedInput
      {...props}
      placeholder="0000 0000 0000 0000"
      minLength={length}
      maxLength={length}
      validationType="aaSmartfuel"
      formatFn={formatAASmartfuel}
      verifyFn={verifyAaSmartFuel}
      toBeVerifiedFn={isToBeVerified}
    />
  );
}

export default AaSmartFuelInput;
