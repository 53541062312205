// @flow
import React from 'react';
import RadioButton from '../RadioButton';
import './RadioButtonList.scss';

type ItemType = {
  value?: any,
  label: string,
};

type Props = {
  name: string,
  title: boolean,
  description: string,
  items: Array<ItemType>|Array<string>,
  value: string,
  onChange: () => void,
  onKeyDown: () => void,
  required: boolean,
  validateItself: boolean,
};

const RadioButtonList = (props: Props) => (
  <div className="radioButtonList">
    <div name={props.name ? props.name + "Title" : null} className="radioButtonList_title">{props.title}</div>
    <div name={props.name ? props.name + "Description" : null} className="radioButtonList_description">{props.description}</div>
    <div className="radioButtonList_container">
      {props.items.map((item, index) => {
        const itemValue = typeof item === 'object' ? item.value : item;
        const label = typeof item === 'object' ? item.label : undefined;
        return (
          <RadioButton
            key={index}
            name={props.name}
            checked={props.value === itemValue}
            value={itemValue}
            label={label}
            onChange={props.onChange}
            required={props.required && !props.value}
            validateItself={props.validateItself}
            onKeyDown={props.onKeyDown}
          />
        );
      })}
    </div>
  </div>
);

export default RadioButtonList;
