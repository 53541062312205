// @flow
import React, { useState, useEffect, useMemo } from 'react'
import { compose, branch, renderComponent } from 'recompose';
import classNames from 'classnames';
import ExpansionPanel from '../ExpansionPanel';

import './Rates.scss';

import { type Rates as RatesType, type Messages, type APIUsageType } from './types';
import { USAGE_TYPE_LOW, USAGE_TYPE_STANDARD } from "./constants";
import {
  areRatesValid,
  hasElectricityRatesError,
  hasGasRatesError,
} from './utils';

import ToggleButton from '../ToggleButton';
import Error from './Error';
import RatesContent from './RatesContent';

type Props = {
  id: string,
  rates: RatesType,
  gasRate?: RatesType,
  isBachPlan: boolean,
  messages: Messages,
  className?: string,
  isOpenByDefault: boolean,
  isNarrow?: boolean,
  isSummary?: boolean,
  isIcon?: boolean,
  gasOnly?: boolean,
  managedContent?: Object,
  setIsStandard?: (status: boolean) => void,
  isLowUser: boolean,
  errorClassName?: string,
  gasUsageType: string,
  onGasUsageToggle: () => void,
};

const Rates = ({
  id,
  rates,
  isBachPlan,
  messages,
  className,
  isOpenByDefault,
  isNarrow,
  isSummary,
  setIsStandard,
  isLowUser,
  isIcon,
  gasRate,
  gasOnly,
  managedContent,
  gasUsageType,
}: Props) => {
  const [isExpanded, setIsExpanded] = useState(isOpenByDefault);
  const [elecUsageType, setElecUsageType] = useState<APIUsageType>(() => isLowUser ? USAGE_TYPE_LOW : USAGE_TYPE_STANDARD);

  useEffect(() => {
    setElecUsageType(isLowUser ? USAGE_TYPE_LOW : USAGE_TYPE_STANDARD);
  }, [isLowUser])

  useEffect(() => {
    if (setIsStandard) {
      setIsStandard(elecUsageType === USAGE_TYPE_STANDARD)
    }
  }, [elecUsageType])

  return (
    <div id={id} className={classNames(className, 'rates')}>
      <div className={classNames("rates_top-row", isSummary ? "rates_top-row--summary" : "")}>
        <div className="rates_top-row_column">
          <ToggleButton
            onToggle={() => setIsExpanded(!isExpanded)}
            expanded={isExpanded}
            expandLabel={messages.expand}
            collapseLabel={messages.collapse}
            className="rates_toggleRates"
          />
        </div>
      </div>
      <ExpansionPanel isExpanded={isExpanded}>
        <RatesContent
          messages={messages}
          rates={rates}
          gasRates={gasRate}
          elecUsageType={elecUsageType}
          gasUsageType={gasUsageType}
          onToggleUsageType={() => setElecUsageType(elecUsageType === USAGE_TYPE_STANDARD ? USAGE_TYPE_LOW : USAGE_TYPE_STANDARD)}
          hasElectricityRatesError={hasElectricityRatesError(rates)}
          hasGasRatesError={gasRate && hasGasRatesError(gasRate)}
          isBachPlan={isBachPlan}
          isNarrow={isNarrow}
          isSummary={isSummary}
          isIcon={isIcon}
          gasOnly={gasOnly}
          managedContent={managedContent}
        />
      </ExpansionPanel>
    </div>
  );
};

export default compose(
  branch(
    ({ rates }) => !areRatesValid(rates),
    renderComponent(Error)
  )
)(Rates);
