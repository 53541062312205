// @flow
import * as React from 'react';
import classNames from 'classnames';
import './StepList.scss';

import StepListItem from './StepListItem';

type Props = {
  children: React.ChildrenArray<React.Element<typeof StepListItem>>,
  className?: string,
};

const StepList = (props: Props) => (
  <ul className={classNames('stepList', props.className)}>
    {props.children}
  </ul>
);

StepList.Item = StepListItem;

export default StepList;
