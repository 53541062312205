// @flow

import React from 'react';
import classNames from 'classnames';

import './Loading.scss';

type Props = {
  isInContainer: Boolean,
  customstyle?: Object,
};

const Loading = (props: Props) => {

  const loadingClass = classNames({
    "loading": true,
    "loading--inContainer": props.isInContainer,
  });

  const spinnerClass = classNames({
    "loading_spinner": true,
    "loading_spinner--inContainer": props.isInContainer,
  });

  const bounce1 = classNames({
    "loading_doubleBounce1": true,
    "loading_doubleBounce1--white": props.isInContainer,
  });

  const bounce2 = classNames({
    "loading_doubleBounce2": true,
    "loading_doubleBounce2--white": props.isInContainer,
  });

  return (
    <div className={loadingClass} style={props.customstyle}>
      <div className={spinnerClass}>
        <div className={bounce1} />
        <div className={bounce2} />
      </div>
    </div>
  );
};

export default Loading;
