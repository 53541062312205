// @flow
import React, { useEffect } from "react";
import { useSpring, animated } from 'react-spring';
import ReactDOM from "react-dom";
import classNames from "classnames";

import { CloseOverlay, Close } from "../../Assets/Icons";
import Button from "../Button";
import Icon from '../Icon';
import Input from "../Input";

import "./Modal.scss";

type Props = {
  name: string,
  buttonTextPrimary: string,
  buttonTextSecondary: string,
  content: node,
  handleChange: () => void,
  value: string,
  handlePrimaryClick: () => void,
  handleSecondaryClick: () => void,
  handleValidationClick: () => void,
  inputType: string,
  isActive: boolean,
  inputLabelText: string,
  title: string,
  buttonDisabled: boolean,
  handleError: () => {},
  errorMessage: string,
  hasError: Boolean,
  className: String,
  journeyStyle: Boolean,
  showClose?: boolean,
  clearSessionStyle?: boolean,
  contentStyle?: Object,
  hideButtonContainer: boolean,
};

const modalRoot = document.getElementById("modalRoot");
const body = document.getElementsByTagName('body')[0];

const Modal = (props: Props) => {
  const contentStyle = classNames({
    'modal_content': true,
    'modal_content--price': props.inputType,
  });

  const [animateModal, setAnimatedModal] = useSpring(() => ({ opacity: 0 }));

  setAnimatedModal({
    opacity: props.isActive ? 1 : 0,
  })

  useEffect(() => {
    body.style.overflow = props.isActive ? 'hidden' : '';
  }, [props.isActive])

  const isPrimaryButtonClass = props.buttonTextSecondary
    ? "toggleCancel"
    : "toggleConfirm";
  return props.isActive
    ? ReactDOM.createPortal(
        <animated.div style={animateModal} className={classNames('modal', {'journeyStyle': props.journeyStyle})}>
          <div name={props.name} className={"modal_container " + props.className}>
            {(props.journeyStyle || props.showClose) && <div className="modal_closeContainer" onClick={props.handlePrimaryClick} aria-label="Close Modal">
                {props.clearSessionStyle ? 
                  <Icon
                    icon={<Close />}
                    customStyle={{width: "18px", height: "18px", marginRight: "14px"}}
								  /> : <CloseOverlay/>
                }
            </div>}
            <div className="modal_contentContainer">
              <h2 className="modal_title">{props.title}</h2>
              <div className={contentStyle} style={props.contentStyle}>
                {props.content}
              </div>
              {props.inputType && (
                <Input
                  validationType={props.inputType}
                  name="input"
                  handleChange={props.handleChange}
                  value={props.value}
                  iconType="edit"
                  labelText={props.inputLabelText}
                  placeholder="0.00"
                  handleError={props.handleError}
                  errorMessage={props.errorMessage}
                  hasError={props.hasError}
                />
              )}
          </div>
          {!props.hideButtonContainer && (
            <div className="modal_buttonWrapper">
              {!props.journeyStyle && <Button
                type="button"
                text={props.buttonTextPrimary}
                handleClick={props.handlePrimaryClick}
                theme={isPrimaryButtonClass}
                aria-label="Close Modal"
                customStyle={props.clearSessionStyle && {fontSize: "16px"}}
              />}
              {props.buttonTextSecondary && (
                <Button
                  type="button"
                  text={props.buttonTextSecondary}
                  handleValidationClick={props.handleValidationClick}
                  handleClick={props.handleSecondaryClick}
                  theme="toggleConfirm"
                  disabled={props.buttonDisabled}
                  customStyle={props.clearSessionStyle && {fontSize: "16px"}}
                />
              )}
            </div>
          )}
          </div>
        </animated.div>,
        modalRoot
      )
    : null;
};

export default Modal;
