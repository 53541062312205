// @flow
import React, { Fragment, useState, useEffect, useRef } from 'react';
import { useSpring, animated, config } from 'react-spring';
import classNames from 'classnames';
import Icon from '../Icon';
import moment from 'moment';
import { validateDateOfBirth } from '../../Utils/ValidationUtil';
import './DateOfBirthInput.scss';
import { Error, Calendar } from '../../Assets/Icons';

export type Props = {
  labelText: string,
  handleChange: () => void,
  value: string,
  placeholder: string,
  required: boolean,
  validationType: string,
  maxLength?: number,
  handleError: () => {},
  inputProps: any,
  hasError: Boolean,
  errorMessage: string,
  invalidMessage: string,
  validateItself: boolean,
};

const DateOfBirthInput = (props: Props) => {
  const [isFocused, setIsFocused] = useState(false);
  const [isValid, setIsValid] = useState(false);
  const inputDay = useRef(null);
  const inputMonth = useRef(null);
  const inputYear = useRef(null);
  const [focused, setFocused] = useState('day');
  const hasValue = props.value.day || props.value.month || props.value.year;
  const noValues = !props.value.day && !props.value.month && !props.value.year;
  const inputType = window.innerWidth >= 640 ? 'text' : 'number';
  const date = [
    {period: 'day', value: props.value.day, placeholder: true, length: 2, ref: inputDay, maxWidth: '145px'},
    {period: 'month', value: props.value.month, placeholder: false, length: 2, ref: inputMonth, maxWidth: '26px'},
    {period: 'year', value: props.value.year, placeholder: false, length: 4, ref: inputYear, maxWidth: props.value.year ? '70px' : '0'},
  ]

  useEffect(() => {
    setIsFocused(props.value.day ? true : isFocused);
  }, [props.value.day]);

  useEffect(() => {
    setTimeout(() => {
      if (!focused) {
        onBlurHandler();
      }
    }, 200);
  }, [focused])

  useEffect(() => {
    props.handleError && !props.required && props.handleError(false);
  }, [props.required, props.handleError]);

  useEffect(() => {
    if (props.value.day, props.value.month, props.value.year) {
      const fakeChangeEvent = {
        preventDefault: () => {},
        target: {value: {'day': props.value.day, 'month': props.value.month, 'year': props.value.year}},
      };
      const isValid = !validateDateOfBirth(fakeChangeEvent, () => {});
      setIsValid(isValid);
    } else {
      setIsValid(false);
    }
  }, [props.value.day, props.value.month, props.value.year]);

  const animateLabel = useSpring({
    opacity: isFocused ? 0.6 : 1,
    bottom: isFocused ? '24px' : '0px',
    fontSize: isFocused ? '14px' : '18px',
    config: config.stiff
  });

  const onChangeHandler = (e) => {
    const value = e.target.value;
    const period = e.target.name;
    const dateObject = {...props.value, [period] : value}
    const backMove = e.keyCode === 37 || e.keyCode === 8;
    const towardsMove = e.keyCode === 39;
    const isNull = (value) => {
      return value === '00';
    }
    if (period === 'year') {
      if (value.length > 4) {
        return;
      }
    } else {
      if (e.keyCode === 9) {
        e.preventDefault()
      }
    }
    if (period === 'day') {
      if (!isNull(value) && (!towardsMove && !backMove) && value < 32 && (value.length === 2 || (value.length === 1 && value > 3))) {
        inputMonth.current.focus();
      }
      if (towardsMove) {
        if (e.target.selectionStart === 2) {
          inputMonth.current.focus();
        }
      }   
    } else if (period === 'month') {
      const days = moment(value, "MM").daysInMonth();
      if (!isNull(value) && (value.length === 2) && value < 13 && (!towardsMove && !backMove)) {
        if (props.value.day <= days) {
          inputYear.current.focus();
        }
      }
      if (towardsMove) {
        if (e.target.selectionStart === 2) {
          inputYear.current.focus();
        }
      } else if (backMove) {
        if (value.length === 0 || e.target.selectionStart === 0) {
          inputDay.current.focus();
        }
      }
    } else if (period === 'year') {
      if (backMove) {
        if (value.length === 0 || e.target.selectionStart === 0) {
          inputMonth.current.focus();
        }
      }
    }
    props.handleError(validateDateOfBirth(dateObject, props.handleChange));
    if (props.inputProps && props.inputProps.onChange) props.inputProps.onChange(event);
  }

  const focusOnField = (period) => {
    setIsFocused(true);
    setFocused(period);
  }

  const onFocusHandler = (event) => {
    setIsFocused(true);
    if(props.inputProps && props.inputProps.onFocus) props.inputProps.onFocus(event);
  }

  const onBlurHandler = () => {
    if (validateDateOfBirth({'day': props.value.day, 'month': props.value.month, 'year': props.value.year}, props.handleChange)) {
      props.handleError(true);
    } else {
      props.handleError(false);
    }
    if (props.inputProps && props.inputProps.onBlur && !focused) props.inputProps.onBlur();
  }

  const focusOnDob = () => {
    if (inputDay && inputDay.current && !inputDay.current.value) {
      inputDay.current.focus();
    }
  }

  return (
    <Fragment>
      <div
        className="inputContainer"
        onClick={focusOnDob}
      >
        {props.labelText && !props.hasError && (
        <animated.label
          style={animateLabel}
          id={props.value.day}
          name={props.value.day}
          htmlFor={props.value.day}
          className="dobInputContainer_label"
          onClick={() => onFocusHandler()}
        >
          {props.labelText}
        </animated.label>
        )}
        {props.hasError && (
          <label
            style={{ color: 'red', 
              opacity: 0.6,
              bottom: '24px',
              fontSize: '14px' }}
            id={props.value.day}
            htmlFor={props.value.day}
            className="dobInputContainer_label"
            onClick={() => onFocusHandler()}
          >
            {props.errorMessage && props.errorMessage}
          </label>
        )}

        <div className="dobInputContainer_dob">
          <div className="dobInputContainer_dob_calendar">
            <Icon name='calendar' icon={<Calendar />} className="dobInputContainer_dob_calendar_icon" />
          </div>
          {date.map((item, index) => (
            <div key={index}>
              <input
                name={item.period}
                type={inputType}
                value={item.value}
                placeholder={isFocused && noValues && item.placeholder ? props.placeholder : ""}
                style={{ maxWidth: hasValue && item.period !== 'year' ? '28px' : item.maxWidth}}
                onChange={onChangeHandler}
                maxLength={item.length}
                ref={item.ref}
                onKeyDown={onChangeHandler}
                onFocus={() => focusOnField(item.period)}
                onBlur={() => setFocused('')}
                pattern="[0-9]*"
              />
              {item.period === 'day' && item.value.length === 2 && <span>/</span>}
              {item.period === 'month' && item.value.length === 2 && <span>/</span>}
            </div>
          ))}
        </div>
        {props.hasError && (<Icon name='errorIcon' icon={<Error />} className="inputContainer_icon--error" /> )}
      </div>
    </Fragment>
  );
};

DateOfBirthInput.defaultProps = {
  handleError: () => {},
  value: {},
}

export default DateOfBirthInput;
