import React, { useState, useEffect, useRef } from "react";
import Icon from '../Icon';
import { ArrowRight } from '../../Assets/Icons';
import './ControlArrows.scss';

type Props = {
	scrollIndex: Number,
	data: [],
	customStyle?: Object,
	getPath: () => {},
	onClick: () => {},
};
const ControlArrows = (props: Props) => {
	const {scrollIndex, data, getPath} = props;

	const scrollRight = () => {
		props.onClick('right', false);
	}

	const scrollLeft = () => {
		props.onClick('left', false);
	}

	return (
		<div className="controlArrows" style={props.customStyle}>
			<div className="controlArrows_block" onClick={scrollLeft}>
				<Icon
					icon={<ArrowRight />}
					size={28}
					className="controlArrows_block_icon--left"
				/>
			</div>
			<div className="controlArrows_block" onClick={scrollRight}>
				<Icon
					icon={<ArrowRight />}
					size={28}
					className="controlArrows_block_icon--right"
				/>
			</div>
		</div>
	)
}

export default ControlArrows;