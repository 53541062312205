// @flow
import * as React from 'react';
import { useSpring, animated, a } from 'react-spring'
import './ExpansionPanel.scss';

import { useMeasure, usePrevious } from '../../Utils/hooks'

type Props = {
  isExpanded?: boolean,
  children: React.Node,
};

const defaultProps = {
  isExpanded: false,
};

const ExpansionPanel = (props: Props) => {
  const previous = usePrevious(props.isExpanded);
  const [bind, { height: viewHeight }] = useMeasure();
  const { height, opacity } = useSpring({
    from: { height: 0, opacity: 0 },
    to: { height: props.isExpanded ? viewHeight : 0, opacity: props.isExpanded ? 1 : 0 },
  });

  const expandedHasChanged = typeof previous !== 'undefined' && previous !== props.isExpanded;

  return (
    <animated.div
      className="expansionPanel"
      aria-expanded={props.isExpanded}
      style={{
        opacity: props.isExpanded && !expandedHasChanged ? 1 : opacity,
        height: props.isExpanded && !expandedHasChanged ? 'auto' : height,
        overflow: props.isExpanded ? 'visible' : 'hidden'
      }}
    >
      <div {...bind}>
        {props.children}
      </div>

    </animated.div>
  )
};

ExpansionPanel.defaultProps = defaultProps;

export default ExpansionPanel;
