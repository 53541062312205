// @flow
import React from 'react';
import classNames from 'classnames';
import './RadioButton.scss';

type Props = {
  name: string,
  className?: string,
  value: string,
  label?: string,
  checked: boolean,
  onChange: () => void,
  onKeyDown: () => void,
  required: boolean,
  validateItself: boolean,
};

const RadioButton = (props: Props) => {
  const radioClassName = classNames(props.className, 'radioButton');

  return (
    <div className={radioClassName} onClick={() => props.onChange(props.value)}>
      <input
        id={props.name ? props.name : null}
        name={props.name ? props.name : null}
        className="radioButton_input"
        value={props.value}
        type="radio"
        checked={props.checked}
        onChange={() => props.onChange(props.value)}
        onKeyDown={props.onKeyDown && props.onKeyDown()}
      />
      <label name={props.name ? props.name + "Label" : null} className="radioButton_label" htmlFor={props.value}>
        <svg
          className="radioButton_svg"
          fill="currentColor"
          preserveAspectRatio="xMidYMid meet"
          height="20"
          width="20"
          viewBox="0 0 20 20"
        >
          <circle
            className="radioButton_radioOutline"
            cx="10"
            cy="10"
            r="8"
            fill="none"
            stroke={props.validateItself && props.required && !props.checked ? "#DD353F" : "#32122f"}
            strokeWidth="2"
          />
          <circle className="radioButton_radioDot" cx="10" cy="10" r="4" fill="#32122f" />
        </svg>
        <span name={props.name ? props.name + "Text" : null} className="radioButton_text">{props.label || props.value}</span>
      </label>
    </div>
  );
}
export default RadioButton;
